import styled from "styled-components"

const TimeStamps = () => {

    const DateField = styled.input`
        font-family: "Open Sans"
        font-size: 40px !important;
        padding: 1rem;
        width: 500px;
        @media(max-width: 700px){
            width: auto ;
            font-size: 15px !important ;
        }
        
        
        `

const Wrapper = styled.div`
        overflow-y: scroll;
        height: calc(100vh - 277px - 6rem);
        @media(max-width: 700px){
            height: calc(100vh - 10px - 6rem);
            padding: 1rem;
        }
    `
    
    const Title = styled.h2`
        font-size: 17px ;
        color: gray;
        font-weight: 500 ;
    `

    return (
        <Wrapper>
        <Title>Jobb skapat</Title>
        <DateField type="datetime-local" value="2022-08-24 07:30" style={{  border: "1px solid #e6e6e6", fontSize: "17px", fontFamily: "Open Sans"}}/>
        
        <Title>Jobb accepterat</Title>
        <DateField type="datetime-local" value="2022-08-24 07:30" style={{border: "1px solid #e6e6e6", fontSize: "17px", fontFamily: "Open Sans"}}/>
        
        <Title>Jobb påbörjat</Title>
        <DateField type="datetime-local" value="2022-08-24 07:30" style={{border: "1px solid #e6e6e6",fontSize: "17px", fontFamily: "Open Sans"}}/>
        
        <Title>På plats</Title>
        <DateField type="datetime-local" value="2022-08-24 07:30" style={{border: "1px solid #e6e6e6",fontSize: "17px", fontFamily: "Open Sans"}}/>
        
        <Title>Jobb slutfört</Title>
        <DateField type="datetime-local" value="2022-08-24 07:30" style={{border: "1px solid #e6e6e6",fontSize: "17px", fontFamily: "Open Sans"}}/>
        
        </Wrapper>
    )   
}

export default TimeStamps