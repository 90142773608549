const verkstader = 

        [
            {type: "loc", name:"Keolis AB", adress: "Keolis, Hjälmarsvägen, Årsta"},
            {type: "loc", name:"Autoexperten", adress: "Autoexperten, Bro Centrum, Blomstervägen, Bro"},
            {type: "loc", name:"Carolina Towers", adress: "Elite Hotel Carolina Tower, Eugeniavägen, Solna"},
            {type: "loc", name:"Iver", adress: "Westfield Mall of Scandinavia, Stjärntorget, Solna"},
               
        ]
        


export default verkstader