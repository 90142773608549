import styled from "styled-components/macro"

const Grid = styled.div`
    display: grid ;
    grid-template-rows: 87px;
    /* border-top: 1px solid black; */
    grid-template-columns: 200px 70% 15% ;
    /* height: 70%; //quick fix */
    //Bugfix
    height: calc(100vh - 5rem);

    @media(max-width: 1000px){
      grid-template-columns: 100% ;
      /* grid-template-rows: 55px; */
      grid-template-rows: 61px;
    }
`
const App = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;
`
const Heading = styled.div`
    @media(max-width: 1000px) {
      display: none ;
    }
    display: flex;
    align-items: center ;
    margin-right: 2rem;
    font-size: 20px ;
    color: gray;
    font-weight: 400 ;
   
`

const MainWindow = styled.div`
  /* height: calc(100vh - 90px - 3rem);  Bugg?*/
  /* overflow: hidden ; */
  grid-area: 2/2/2/3;
  position: relative;
  @media(max-width: 700px){
    grid-area: initial ;
  }
`

const TopRowWrapper = styled.div`
    display: flex ;
    /* max-height: 55px; */
    grid-area: 1/2/1/3;

    @media(min-width: 700px){
      max-height: 55px ;
    }

    ::-webkit-scrollbar {
  
    border-radius: 5px;
    background: rgb(228, 228, 228);
    cursor: pointer;
    width: 1px;
    height : 5px ;
    }
    ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: #47BCF4;
    width:5px ;
    }

    @media(max-width: 1000px){
      overflow-x: auto;
      grid-area: initial ;
    }
    // padding: 1rem ;
`

export { Grid, App, Heading, MainWindow, TopRowWrapper }