import LeftCol from "./Layouts/LeftCol"
import { App, Grid, LeftColWrapper, Heading, MainWindow, TopRowWrapper } from "./Layouts/StyledComponents"
import styled from "styled-components/macro"
import Dropdown from "../SystemModules/dropdown"
import { useState } from "react"


const NewAssignment = () => {

    const [displayMap, toggleMap] = useState(false)

    const jobblista = [
        { name: "AKG 949" },
        { name: "BCC 825" },
        { name: "BXR 995" },
        { name: "DST 292" }
    ]

    const stationsLista = [
        { name: "Göteborg" },
        { name: "Kungälv" },
        { name: "Kungsbacka" },

    ]

    const rollLista = [
        { name: "Koordinator" },
        { name: "Eget jobb" },


    ]

    const hanteringsLista = [
        { name: "Bärgarna AB" },
    ]

    const uppdragsLista = [
        { name: "Bärgning" },
        { name: "TMA" },
        { name: "Transport" },
        { name: "Flertransport" },
    ]



    const [dropDown1, setDropdown1] = useState("")
    const [dropDown2, setDropdown2] = useState("")
    const [dropDown3, setDropdown3] = useState("")
    const [dropDown4, setDropdown4] = useState("")
    const [dropDown5, setDropdown5] = useState("")

    const [selected1, setSelected1] = useState("Befintliga kunder")
    const [selected2, setSelected2] = useState("Välj station")
    const [selected3, setSelected3] = useState("Välj roll")
    const [selected4, setSelected4] = useState("Bärgarna AB")
    const [selected5, setSelected5] = useState("Välj uppdragstyp")
    const [selected, setSelected] = useState(0)


    const GridNewJob = styled.div`
        display: grid ;
    grid-template-rows: 87px;
    /* border-top: 1px solid black; */
    grid-template-columns: 200px 70% 15% ;
    /* height: 70%; //quick fix */
    //Bugfix
    height: calc(100vh - 2px - 5rem);

    @media(max-width: 1000px){
      display: block;  
      grid-template-columns: 100% ;
      grid-template-rows: 55px;
    }
    `

    const Window = styled.div`
        overflow-y: scroll ;
        display: flex ;
        grid-area: 2/2/2/2;
        flex-wrap: wrap ;
        // margin-left: 200px;
        @media(max-width: 700px){
            margin: 0 ;
            height: calc(100% - 1rem + 16px);
        }
        `


    const FormWrapper = styled.div`
        width: 100%;
        display: flex ;
        margin-right: 1rem;
        /* margin-top: 1rem; */
        padding: 0 1rem;
        flex-flow: row ;
      
        flex-wrap: wrap ;
        /* background: #f1f1f1; */
        /* border-top: 3px solid #4bbef4; */
        max-width: 700px ;
        @media(max-width: 700px){
            margin-right: 0;
        }
    
    `



    const Form = styled.input`
        padding: .5rem;
        width: -webkit-fill-available;
        margin-bottom: .5rem ;
        // margin-right: 1rem; 
        border: 1px solid #d1d1d1;
        border-radius: 5px ;
        height: 1rem;
        width: ${props => props.width};
        @media(max-width: 700px){
           
            margin-right: 0; 
        }
`

    const InputWrapper = styled.div`
        width: 100%;
    
        h3 {
            font-size: 12px ;
            font-weight: 400 ;
            margin: 0 ;
        
        }

    
     
    
`

    const FormRow = ({ title, type, width }) => {
        return (
            <InputWrapper>
                <h3> {title} </h3>
                <Form type={type} width={width} />
            </InputWrapper>
        )
    }

    const SubTitle = styled.h2`
    width: 100%;
    color: #494949;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
`

    const DropdownWrapper = styled.div`

`

    const FlexWrapper = styled.div`
    display: flex;
    flex-flow: row;
    margin-bottom: 2rem;
    flex-wrap: wrap;
`

    const Infobox = styled.div`
    display: grid; 
   
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem;
    
    background: #0B375C;
    color: white;
    @media(max-width: 700px){
        grid-template-columns: 1fr 1fr ;
        padding: .5rem;
        font-size: 12px;
    }
`
    const Col1 = styled.div``
    const Col2 = styled.div``
    const Bold = styled.div`
    font-weight: 800;
`


    const FormFlex = styled.div`
    display: grid;
    grid-gap: .5rem;
    width: 100%;
    grid-template-columns: 50% 50%;
    @media(max-width: 700px){
        grid-template-columns: 100%;
        }
`

    const TextArea = styled.textarea`
        padding: 0.5rem;
    width: -webkit-fill-available;
    margin-bottom: 0.5rem;
    /* margin-right: 1rem; */
    border: 1px solid #d1d1d1;
    border-radius: 5px;

    font-family: "Open Sans";
`

    const TextAreaTitle = styled.h3`
    width: 100%;
`

    const CurrentLocation = styled.div`
    background-color: #0B375C;
    text-align: center;
    font-size: 15px ;
    width: 100%;
    padding: 2rem;
    color: white;
    border-radius: 5px ;
`

    const LargeButton = styled.button`
    background-color: #47BCF4 ;
    font-size: 12px ;
    color: white;
    border: none;
    outline: none;
    padding: 1rem;
    margin: 1rem 0;
    margin-right: 1rem;
    border-radius: 5px ;
    
`

    const DropdownWrapperRow = styled.div`
    display: flex;
    width: -webkit-fill-available;
    flex-direction: row-reverse ;

    @media(max-width: 700px){
            flex-direction: column-reverse  ;
        }
`

    const Status = styled.div`
    h3 {
        font-size: 14px;
        margin-bottom: 5px ;

    }

    p {
        font-size: 12px;
        margin: 0;
    }
`

    const TabWrapper = styled.div`
        display: flex; 
        flex-direction: row;
      
    `



    const Tabs = styled.div`
        padding: 1rem;
        cursor: pointer;
        color: gray;
        background-color: ${props => props.selected ? "white" : "white"};
        border-bottom: ${props => props.selected ? "2px solid #47bcf4" : ""};
        font-weight: ${props => props.selected ? "600" : "100"};
    `

    const Map = styled.div`
        display: ${props => props.display ? "block" : "none"};
        width: 100%;
        iframe{
            width: 100%;
            /* max-width: 800px; */
            height: 300px ;
            border: 0px;
        }
    `

    const Search = () => {

        const SearchBar = styled.input`

            margin: 0 auto;
            padding: 1rem;
            // background:red;
            // padding-left: 4rem;
            text-align:center;
            width: 211px;
            font-size: 20px;
            border-radius: 5px;
            border: 1px solid white;
        `

        const Icon = styled.img`
            display: block;
            position: absolute;
            top: 17px;
            left: 12px;
            width: 20px;
            height: 20px;
          
        `

        const SearchWrapper = styled.div`
        background-color: #0B375C;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        position: relative;
            grid-area: 1/1/1/3;
            @media(max-width: 700px){
                grid-area: 1/1/1/1;
            }
            h2 {
                color: white;
                font-weight: 300;
                text-align: center;
            }
            
            `
            const FormPlaceholder = styled.div`
                position: relative;
                margin: 0 auto;
            `
            
        return (
            <SearchWrapper>
                <h2> Sök fordonsuppgifter</h2>
                <FormPlaceholder>
                    <Icon src="/search_black.png" />
                    <SearchBar placeholder="ABC 123" />
                </FormPlaceholder>
            </SearchWrapper>
        )
    }

    return (
        <App>
            <GridNewJob>
                <TopRowWrapper>
                    <Heading>
                        Nytt Jobb
                    </Heading>
                </TopRowWrapper>

                <LeftCol />
                <Window>
                    <MainWindow>
                        <FlexWrapper>
                            <FormWrapper>
                                <SubTitle> Fordon</SubTitle>
                                <FormFlex>
                                    <Search />

                                    <FormRow title="Typ av fordon" type="text" />
                                    <FormRow title="Färg" type="text" />
                                    <FormRow title="Tillverkare" type="text" />
                                    <FormRow title="Försäkring" type="text" />
                                    <FormRow title="Modell" type="text" />
                                </FormFlex>

                                <Infobox>

                                    <p>Fyrhjusdrift: <Bold> Nej</Bold></p>
                                    <p>Transmission: <Bold> Manuell</Bold></p>
                                    <p>Bränsle: <Bold> Bensin</Bold></p>
                                    <p>Tjänstevikt: <Bold> 1200 kg</Bold></p>

                                    <p>Modellår: <Bold> 1990</Bold></p>
                                    <p>Chassinummer: <Bold> PB201905270000002</Bold></p>
                                    <p>Totalvikt: <Bold> 1600</Bold></p>

                                </Infobox>
                            </FormWrapper>

                            <FormWrapper>
                                {/* <Dropdown type="blue" selected={selected1} list={jobblista} dropdown={selected1} setDropdown={setSelected1} title="Kunder" /> */}
                                <SubTitle> Kunduppgifter</SubTitle>
                                <FormFlex>
                                    <FormRow title="Personnummer" type="text" />
                                    <FormRow title="Namn" type="text" />
                                    <FormRow title="Personligt" type="text" />
                                    <FormRow title="Adress" type="text" />
                                    <FormRow title="Regnummer" type="text" />
                                    <FormRow title="Postnummer" type="text" />
                                </FormFlex>
                            </FormWrapper>

                            <FormWrapper>
                                <SubTitle> Problembeskrivning</SubTitle>
                                <FormFlex>
                                    <FormRow title="Problem" type="text" />
                                    <FormRow title="Problembeskrivning" type="text" />
                                    <FormRow title="Telefon beställare" type="text" />
                                    <FormRow title="E-postadress till ägare" type="text" />
                                    <FormRow title="Internnummer" type="text" />
                                    <FormRow title="Kund" type="text" />
                                    <FormRow title="Beställare" type="text" />
                                    <FormRow title="Telefon på plats" type="text" />
                                </FormFlex>
                            </FormWrapper>

                            <FormWrapper>
                                <SubTitle> Plats</SubTitle>
                                <CurrentLocation>
                                    Västberga Allé 36, 126 30 Hägersten
                                </CurrentLocation>
                                <LargeButton> Nuvarande plats</LargeButton>
                                <LargeButton onClick={() => toggleMap(true)}> Sök plats på kartan</LargeButton>
                                <TextAreaTitle> Beskrivning skadeplats</TextAreaTitle>
                                <Map display={displayMap}>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33951.50400487204!2d11.914620085011439!3d57.8697318792364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46455e2013b154db%3A0x650dbaf5f076f8c9!2zS3VuZ8OkbHY!5e0!3m2!1ssv!2sse!4v1661374789896!5m2!1ssv!2sse" width="1000" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                </Map>
                                <TextArea rows="10" title="Beskrivning skadeplats" type="text"  ></TextArea>

                                <DropdownWrapperRow>
                                    <Dropdown type="blue" selected={selected5} list={uppdragsLista} dropdown={selected5} setDropdown={setSelected4} title="Typ av uppdrag" />
                                    <Dropdown type="blue" selected={selected4} list={hanteringsLista} dropdown={selected4} setDropdown={setSelected4} title="Uppdraget hanteras av" />
                                    <Dropdown type="blue" selected={selected3} list={rollLista} dropdown={selected3} setDropdown={setSelected3} title="Välj roll" />
                                    <Dropdown type="blue" selected={selected2} list={stationsLista} dropdown={selected2} setDropdown={setSelected2} title="Välj station" />
                                </DropdownWrapperRow>
                                <Status>
                                    <h3>Status</h3>
                                    <p>Stationen koordinerad av Marcus Gustavsson sedan 2022-04-22 12:30. Just nu är 3 bärgare aktiva på stationen.</p>
                                </Status>



                            </FormWrapper>
                        </FlexWrapper>

                    </MainWindow>
                </Window>
            </GridNewJob>
        </App>
    )
}
export default NewAssignment