import react, {useState} from "react"
import styled from "styled-components/macro"
import Dropdown from "../../SystemModules/dropdown"
import List from "../../SystemModules/list"
const Kopiera = ({setModal}) => {
    
    const [selected, setSelected] = useState("Göteborg")

    const stationer = [
        {name: "Göteborg"},
        {name: "Kungälv"},
        {name: "Kungsbacka"},
    
    ]

    const list = [
        {name: "Frank Andersson", station: "Göteborg", status: ["Aktiv", "Bärgare"]}, 
        {name: "Marcus Gustavsson", station: "Göteborg",status: ["Aktiv", "Bärgare"]}, 
        {name: "Per Falk", station: "Göteborg", status: ["Aktiv", "Bärgare", "Koordinator"]}

    ]

    const ExitButton = styled.div`
    width: fit-content;
    border-radius: 20px;
    min-width: 150px;
    // border: 1px solid #47bcf4;
    padding: .5rem;
    position: relative ;
    font-size: 16px;
    text-align:center ;
    color: #FFC36C;
    margin-bottom: 1rem;
    /* margin: 0 auto; */
    border: 2px solid #FFC36C;
    
    cursor: pointer;
    ::before {
        content: "";
        display: block;
        position: absolute ;
        top: 11px;
        height: 30px ;
        width: 30px;
        background-image: url("${props => props.icon}");
        object-fit: cover ;
        object-position: center ;
        background-repeat: no-repeat ;

    }
    
    `

    const TabsWrapper = styled.div`
        display: flex;
        justify-content: space-evenly ;
        max-width: 500px ;
        margin: 0 auto;
    `
    const Tab = styled.div`
        color: #707070;
        cursor: pointer ;
        
        font-weight: ${props => props.selected? "700": "300"};
        border-bottom: 2px solid ${props => props.selected? "#47BCF4": "white"} ;
        padding-bottom: 5px ;
    `

    const Fence = styled.div `
        margin: 2rem 1rem;
        max-width: 800px ;
        padding: 1rem 0;
        border-radius: 0px ;
        position: relative ;
        border-top: 1px solid #B6B6B6;
        ::before {
            position: relative ;
            display: flex ;
            justify-content:center ;
            top: -28px;
            font-weight: 600 ;
            padding: 0 .4rem;
            width: fit-content;
            margin: 0 auto;
            background-color: white ;
            content: "${props => props.title}";
        }
    `

    const CtaWrapper = styled.div`
        display: flex; 
        justify-content: center;
        margin: -15px 0;

    `

    const Cta = styled.div`
      @media(max-width: 500px){
        font-size: 10px;
        padding: 0.5rem 0.4rem;
    }
    border: 1px solid #47BCF4;
    height: fit-content;
    color: #47BCF4;
    border-radius: 5px ;
    padding: .5rem 1rem;
    font-weight: 600 ;
    font-size: 14px ;
    display: flex ;
    margin: 0 .5rem;
    ::before {
        content: "";
        position: relative ;
        display: inline-block ;
        width: 20px ;
        background-image: url("${props => props.icon}");
        background-size: contain ;
        margin-right: 5px;
        height: 20px;
        @media(max-width: 500px){
            height: 15px;
            width: 15px;
    }
        /* background-color: #74BCF4 ; */
    }
    `

    const Wrapper = styled.div`
        margin: 1rem;
    
    `
    
    const DropdownWrapper = styled.div`
      display: flex;
      justify-content: center ;
    `
    
    return(
        <Wrapper>
         <ExitButton icon="/yellow_arrow.png"onClick={()=>setModal(false)}>Stäng</ExitButton>
        <TabsWrapper>
            <Tab selected={true}>Bärgning</Tab>
            <Tab selected={false}>TMA</Tab>
            <Tab selected={false}>Transport</Tab>
        </TabsWrapper>    

        <Fence title="Kopiera till samma station">
            
        <CtaWrapper>    
            <Cta icon="/copy_blue.png">Eget jobb</Cta> 
            <Cta icon="/copy_blue.png">Dispatch</Cta> 
        </CtaWrapper>
        
        </Fence> 

        <Fence title="Kopiera till annan station">
            <DropdownWrapper>
                <Dropdown style={{width: "1400px"}} type="blue" selected={selected} list={stationer} dropdown={selected} setDropdown={setSelected} width="override" title="Välj station" />
            </DropdownWrapper>    
     
            <List list={list} />        
        </Fence>       

        </Wrapper>

    )
}
export default Kopiera