import styled from "styled-components/macro"
import List from "../../SystemModules/list"
import {useState} from "react"
const Kunder = ({setModal})=> {

    const [showOverlay, setOverlay] = useState(false)

    const list = [
        {name: "Frank Andersson", station: "Strökund", status: ["Beställare", "Ägare"]}, 
        {name: "Marcus Gustavsson", station: "Uppdraggivare", icon: "/bl.PNG",status: ["Ägare"]}, 
        {name: "Folksam", station: "Försäkringsbolag", icon: "/folksam.PNG", status:  ["Försäkringsbolag", "Utgivare" ]}

    ]


     const ExitButton = styled.div`
        width: fit-content;
        border-radius: 20px;
        min-width: 150px;
        // border: 1px solid #47bcf4;
        padding: .5rem;
        position: relative ;
        font-size: 16px;
        text-align:center ;
        color: #FFC36C;
        margin-bottom: 1rem;
        /* margin: 0 auto; */
        border: 2px solid #FFC36C;
        
        cursor: pointer;
        ::before {
            content: "";
            display: block;
            position: absolute ;
            top: 11px;
            height: 30px ;
            width: 30px;
            background-image: url("${props => props.icon}");
            object-fit: cover ;
            object-position: center ;
            background-repeat: no-repeat ;

        }`

    const Wrapper = styled.div`
        padding: 1rem;
   
        position: relative ;
    `
    const InfoWrapper = styled.div`
        display: flex;
      
    `


    const Infobox = styled.div`
        max-width: 200px;
        margin: 0 .4rem;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        display: flex; 
        flex-flow: row;
        justify-content: space-between ;
        align-items: center ;
        padding: .5rem;
    `
    const Col = styled.div`
        display: flex ;
        flex-flow: column;
    `
    const InfoboxTitle = styled.h2`
        margin: 0;
        display: flex ;
      
        align-items: center ;
        margin-bottom: .5rem;
        padding: 0;
        font-size: 16px ;
        font-weight: 500 ;
        color: #404040;
    `
    const InfoboxContent = styled.p`

        margin: 0;
        padding: 0;
        font-size: 10px ;
        color: #404040;
    `
    const InfoBoxIcon = styled.img`
        height: 20px ;
        width: 20px ;
        margin-right: 10px;
    `

    const ListWrapper = styled.div``


    const Overlay = styled.div`
    display: ${props => props.display? "block": "none"};
        position: absolute ;
        top: 0;
        padding: 1rem;
        margin: 1rem;
        background-color: red ;
        left: 0;
        // height: 100%;
        width: -webkit-fill-available;
        background-color: #F7F7F7 ;
    `

    const Close = styled.div`

    `

    const Title = styled.h1`
        margin: 0;
        padding: 0;
        font-weight: 400;
        color: #404040;
        font-size: 12px;
    `

    const Input = styled.input`
        padding: .5rem;
        margin-right: ${props => props.margin? "10px": "0px"};
        width: -webkit-fill-available;
        max-width: 100%;
        border: 1px solid #AFAFAF ;
        border-radius:  5px;
    `

    const Fence = styled.div`
    border: 1px solid #C4C4C4;
    border-radius: 5px ;
    margin: 1rem 0;
    background-color: white ;
    padding: 1rem;

    
    `

    const FenceTitle = styled.h2`
            font-weight: 400;
            font-size: 16px;
            margin: 0;
            margin-bottom: 1rem ;
            color: #404040;
    `

    const Label = styled.label`
        margin-right: .5rem;
    `

    const Row = styled.div`
       
    `

    const CheckBox = ({title})=> {
        
        return (
        <Row>
          <input type="checkbox" id={title} name={title} />
          <Label for={title}>{title}</Label>
        </Row>

        )
    }


    const TextField = ({title, margin, placeholder})=>{
        
        const Wrapper = styled.div`
            display: block;
            width: -webkit-fill-available;
            
        `
        
        return (
            <Wrapper>
            <Title> {title}</Title>
            <Input placeholder={placeholder} margin={margin}/>
            </Wrapper>

        )
    }

    const Inline = styled.div`
        display: flex;
        flex-flow: row;
        @media(max-width: 425px){
            width: 50%;
        }

    `


    const Cta = styled.div`
        cursor: pointer;
        background: ${props => props.type==="delete"? "none": "#8AC77D"};
        width: 200px;
        text-align:center ;
        text-decoration: ${props => props.type==="delete"? "underline": "none"}; ;
        padding: 1rem;
        margin: 0 auto;
        border-radius: 50px ;
        color: ${props => props.type==="delete"? "red": "white"};
    `
    

    return(
        <Wrapper>

        <ExitButton icon="/yellow_arrow.png"onClick={()=>setModal(false)}>Stäng</ExitButton>
        <Overlay display={showOverlay}> 
        <ExitButton icon="/yellow_arrow.png"onClick={()=>setOverlay(false)}>Stäng</ExitButton>
            <Fence>
                <FenceTitle>Kunduppgifter</FenceTitle>
                <TextField title="Företag/Beställare" placeholder={"Marcus Gustavsson"}/>
                <TextField title="Gatuadress" placeholder={"Orrekulla Industrigata 25"}/>
                <Inline>
                <TextField margin={true} title="Postnummer" placeholder={"425 36 "}/>
                <TextField title="Ort" placeholder={"Hisings Kärra"}/>
                </Inline>
                <TextField title="E-postadress" placeholder={"info@bargningslogistik.se"}/>
            </Fence>
            <Fence>
              
                <CheckBox title="Moms"/>
             
            </Fence>
            <Fence>
                <FenceTitle>Roller</FenceTitle>
                <CheckBox title="Beställare"/>
                <CheckBox title="Försäkringsbolag"/>
                <CheckBox title="Utgivare"/>
                <CheckBox title="Ägare"/>
            </Fence>

            <Cta>Uppdatera</Cta>
            <Cta type="delete">Ta bort</Cta>
        </Overlay>
       
        <InfoWrapper>
            <Infobox>  
                <Col> 
                    <InfoboxTitle> <InfoBoxIcon src="/infobox_yellow.png"/> Beställare</InfoboxTitle>
                    <InfoboxContent>Använd uppgifter ifrån beställaren och lägg till den som intressent i ärendet.</InfoboxContent>
                </Col>
                {/* <InfoBoxIcon src="/infobox_yellow.png"/> */}
            </Infobox>

            <Infobox>  
                <Col> 
                <InfoboxTitle><InfoBoxIcon src="/infobox_yellow.png"/>  Ägare</InfoboxTitle>
                <InfoboxContent>Använd uppgifter ifrån fordonsuppgifter och lägg till den som intressent i ärendet.</InfoboxContent>
                </Col>
                {/* */}
            </Infobox>

        </InfoWrapper>

        <ListWrapper >
            <div onClick={()=> setOverlay(true)}>
            <List list={list} cta="arrow"/>
            </div>

        </ListWrapper>
    

        </Wrapper>

    )
}

export default Kunder