import styled, {css} from "styled-components/macro"
import { useState } from "react"
import Kopiera from "./utils/kopiera"
import List from "../SystemModules/list"
import Kunder from "./utils/kunder"
import Kamera from "./utils/kamera"
import Karta from "./utils/karta"
import Rapport from "./utils/rapport"
const UtilsMenu = () => {


    const [modalShow, setModal] = useState(false)
    const [selected, setSelected] = useState(1)
    const [selectedKarta, setSelectedKarta] = useState(0)


  

    const Checkbox = styled.input.attrs({ type: 'checkbox' })`
        height: 20px;
        width: 20px;
        margin: 1rem;
        @media(max-width: 700px){
            margin: .5rem;
        }
     `

    const InputWrapper = styled.div`
    width: 100%;
 
    h3 {
        font-size: 12px ;
        font-weight: 400 ;
        margin: 0 ;
       
    }
    
   

    label {
        font-size: 12px;
        
    }
    `

    const FormWrapper = styled.div`
        width: 100%;
        display: flex;
        margin-right: 1rem;
        margin-top: 1rem;
        padding: 1rem;
        flex-flow: row;
        flex-wrap: wrap;
        background: #f1f1f1;
        border-top: 3px solid #4bbef4; 
        @media(max-width: 700px){
            margin-right: 0;
            width: auto;
        }
    `
    const Form = styled.input`
        padding: .5rem;
        width: -webkit-fill-available;
        margin-bottom: .5rem ;
        margin-right: 1rem; 
        border: 1px solid #d1d1d1;
        border-radius: 0px ;
        height: 1rem;
        width: ${props => props.width};
        @media(max-width: 700px){
            width:  -webkit-fill-available;
            margin-right: 0;
            
        }
    `


    const Tabs = styled.div`
            padding: 1rem;
            cursor: pointer;
            color: gray;
            background-color: ${props => props.selected ? "white" : "white"};
            border-bottom: ${props => props.selected ? "2px solid #47bcf4" : ""};
            font-weight: ${props => props.selected ? "600" : "100"};
        `

    const Icon = styled.img`
            margin-right: .5rem;
            width: auto;
            height: 20px ;
        `

    const TabWrapper = styled.div`
            display: flex; 
            flex-direction: row;
        `


    const Button = styled.button`
            
    cursor: pointer;
    padding: 1rem;
    text-align: center ;
    display: flex ;
    align-items: center ;
    font-size: 15px ;
    

    /* text-transform: uppercase ; */
    background-color: ${props =>
            props.type === "green" ? "white" :
                props.type === "blue" ? "white" :
                    props.type === "red" ? "white" : ""
        } ;
    margin: 1rem 0rem;
    margin-right: .5rem;
    color: ${props =>
            props.type === "green" ? "#398C27" :
                props.type === "blue" ? "#58B7D4" :
                    props.type === "red" ? "#F56060" : ""
        } ;
    border: 1px solid;
    border-radius: 10px ;
    border-color: ${props =>
            props.type === "green" ? "#398C27" :
                props.type === "blue" ? "#58B7D4" :
                    props.type === "red" ? "#F56060" : ""
        }  ;
    // font-weight: 600 ;
    width: fit-content ;
      @media(max-width: 700px){
        /* width: auto; */
        line-height: 1;
        height: 38px;
        padding: 0 .5rem;
        min-width: 50px;
        text-align: center;          
              }
      
  `


    const Menu = styled.nav`
        height: 50px; 
        width: 100%;
        display: flex;  
        background: linear-gradient(91.36deg, #43B0FF 1.53%, #4DD4FF 100%);
        bottom: 0px;
        border: 1px solid #43B0FF;      
        display: flex;  
        flex-direction: row ;
        @media(max-width: 1000px){
            justify-content: space-evenly;
        }
        /* justify-content: space-evenly ; */
        left: 50 ;
    `

    const Field = (props) => {

        const Lable = styled.div``
        const Input = styled.input``
        const LabelWrapper = styled.div`
               
            `

        return (
            <LabelWrapper>
                <Lable> {props.text}</Lable>
                <Input type={props.type} />
            </LabelWrapper>
        )
    }



    const Table = styled.div`
        /* padding: 1rem; */
        @media(max-width: 700px){
            display: none;
        }
        `

    const TableHeader = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        background: #e6e6e6;
        color: #464646;
        p {
            padding: 0 1rem;
        }
        `
    const TableRow = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin: 0;
        
        background: white;
        color: gray;
        p {
            padding: 0 1rem;
        }
        `

    const BottomMenu = styled.nav`
        height: 50px; 
        width: 100%;
        margin-top: 1rem;
        background: linear-gradient(91.36deg, #43B0FF 1.53%, #4DD4FF 100%);
        // position: fixed ;
        // bottom: 0px;
        display: flex ;
        flex-direction: row ;
        justify-content: space-evenly ;
        // left: 50 ;
    `

    const MenuButton = styled.div`   
        width: fit-content ;
        transition: 1s border;
        display:flex ;
        flex-direction: column ;
        cursor: pointer;
        @media(min-width: 700px){
            margin: 0 2rem;
        }
        align-items:center ;
        justify-content:center ;
        padding:.5rem ;
        border-bottom: 0px solid white;
        ${props => props.show && css`
            border-bottom: 3px solid white;`
        };
        
        p {
            color: white;
            margin: 0px;
            font-size:12px ;
        }
      
    `
    const MenuIcon = styled.img`
        width:20px;
        height: auto ;
        margin: 0;
    `

    const UtilModal = styled.div`
        position: absolute;
        display: ${props => props.modalShow !== false ? "block" : "none"};
        top: 0px;
        left: 0px;
        height: calc(100vh - 336px + 53px + 2rem);
        @media(max-width: 1000px){
            // height: calc(100vh - 268px + 119px);
            height: calc(100vh - 12.2rem);
        }
        z-index: 900;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        background: white;
        
    `

    const Wrapper = styled.div`
            margin: 1rem;
        iframe{
            width: 100%;
            border: none;
        }

        h3, h2, p {
            color: gray;
        }

    `

    const TopWrapper = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items:center;
        flex-flow: row;
        background-color: #f3f3f3;;
        align-items: center;
        
        h1 {
        }
    `

    // const Button = styled.button`
    //         border: none;

    // color: white;
    // margin-right: .5rem;
    // padding: 1rem;

    // background: #ffffff;
    // border: 1px solid #4e4e4e;
    // color: #4e4e4e;
    // font-weight: 500;
    // font-size: 12px;
    // `

    const ExitButton = styled.div`
        width: fit-content;
        border-radius: 20px;
        min-width: 150px;
        // border: 1px solid #47bcf4;
        padding: .5rem;

        font-size: 16px;
        text-align:center ;
        color: #FFC36C;
        
        /* margin: 0 auto; */
        border: 2px solid #FFC36C;
        
        cursor: pointer;
        ::before {
            content: "";
            display: block;
            position: absolute ;
            top: 29px;
            height: 30px ;
            width: 30px;
            background-image: url("${props => props.icon}");
            object-fit: cover ;
            object-position: center ;
            background-repeat: no-repeat ;

        }
    `

    const Details = styled.details`
    padding: 1rem;

  
    margin: 1rem;
`

    const CloseModal = () => {
        return (
            <ExitButton onClick={() => setModal(false)} icon="/yellow_arrow.png"> Stäng </ExitButton>
        )
    }

    const Color = (props) => {

        const ColorRowWrapper = styled.div`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        `

        const ColorCircle = styled.div`
            min-height: 15px;
            min-width: 15px;
            border: 1px solid black;
            margin-right: 40px;
            border-radius: 50%;
            background-color: ${props => props.color};
        `

        const Row = styled.div`
            width: fit-content;
            display:flex; 
            flex-flow: row-reverse;
            justify-content: flex-end;
            align-items: center;
        `

        return (
            <ColorRowWrapper>
                <Row>
                    <p>{props.title}</p>
                    <ColorCircle color={props.color} />
                </Row>
            </ColorRowWrapper>
        )
    }

 

    const RowWrapper = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media(max-width: 700px){
            grid-template-columns: 1fr;
        }
    `

    const ButtonWrapper = styled.div`
        display:flex; 
        flex-flow: row;
    `
    // const Karta = () => {

    // const MapDetails = styled.div`
    //     display: ${props => props.display? "block": "none"};
    // `

    // const Filter = styled.div`
    //     display: flex;
    //     // justify-content: center;
    //     align-items:center;
    // `

    //     return (
    //         <Wrapper>
    //             <CloseModal />
    //             <TabWrapper>
    //                 <Tabs selected={selectedKarta === 1} onClick={() => setSelectedKarta(1)}>Skadeplats</Tabs>
    //                 <Tabs selected={selectedKarta === 2} onClick={() => setSelectedKarta(2)}>Destination</Tabs>

    //             </TabWrapper>
    //             <MapDetails display={selectedKarta !== 0}>
    //                 <FormWrapper>
    //                     <h3>Skadeplats</h3>
    //                     <InputWrapper>
    //                         <h3>Position</h3>
    //                         <Form width="500px" />
    //                     </InputWrapper>

    //                     <InputWrapper >
    //                         <h3>Beskrivning</h3>
    //                         <Form width="500px" />
    //                     </InputWrapper>

    //                     <ButtonWrapper>
    //                         <Button type="green">Ange nuvarande adress </Button>
    //                         <Button type="green">Ange kordinater </Button>


    //                     </ButtonWrapper>
    //                     <InputWrapper>

                            
    //                         <h3>Filter på typ av resurs</h3>
    //                     <Filter>
    //                         <Checkbox id="1" name="1"/>
    //                         <label for="1"> Verkstäder</label> 
    //                         <Checkbox id="2" name="2"/>
    //                         <label for="2"> Uppställningsplatser</label> 
    //                     </Filter>
                            
    //                     <InputWrapper>
    //                         <h3>Filter</h3>
    //                         <Form width="500px" />
    //                     </InputWrapper>

                  
    //                     </InputWrapper>
    //                 </FormWrapper>
    //             </MapDetails>

    //             <ButtonWrapper>
    //                 <Button type="green">Karta </Button>
    //                 <Button type="green">Lista </Button>
    //                 <Button type="green">Visa rutt </Button>

    //             </ButtonWrapper>
    //             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33951.50400487204!2d11.914620085011439!3d57.8697318792364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46455e2013b154db%3A0x650dbaf5f076f8c9!2zS3VuZ8OkbHY!5e0!3m2!1ssv!2sse!4v1661374789896!5m2!1ssv!2sse" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    //             <Details>
    //                 <summary>Kartinformation</summary>
    //                 <RowWrapper>
    //                     <Color color="#87c44f;" title="Station som används" />
    //                     <Color color="#a1ff4a;" title="Tillgänglig station" />
    //                     <Color color="#c5ff8f;" title="Tillgänglig station, annat företag" />
    //                     <Color color="#ffff8f;" title="Uppställningsplats" />
    //                     <Color color="#ffff26;" title="Verkstad" />
    //                     <Color color="#e8fff8;" title="Sökträff" />
    //                     <Color color="#abfff8;" title="Klick på kartan" />
    //                     <Color color="#00a600;" title="Utgångspunkt för bärgning" />
    //                     <Color color="#0000ba;" title="Destination" />
    //                     <Color color="#d60000;" title="Upphämtningsplats" />
    //                     <Color color="#d60000;" title="Skadeplats" />
    //                 </RowWrapper>
    //             </Details>
    //         </Wrapper>
    //     )
    // }



   

    const Förstesällningar = () => {

        const DateField = styled.input`
        font-family: "Open Sans";
        font-size: 16px !important;
        padding: 1rem;
        width: 500px;

        @media(max-width: 700px){
            width: auto ;
            font-size: 15px !important ;
        }
        `

        const Title = styled.div`
            font-size: 15px ;
            margin: 1rem 0;
        `
        

        return (
            <Wrapper>
              <CloseModal />
                <Title>Hämtas senast:</Title>
                <DateField type="datetime-local" value="2022-08-24 07:30" style={{fontSize: "16px", fontFamily: "Open Sans"}}/>
                <Title>Lämnas senast:</Title>
                <DateField type="datetime-local" value="2022-08-24 07:30" style={{fontSize: "16px", fontFamily: "Open Sans"}}/>

                <Button type="green"> Förbeställ</Button>
            </Wrapper>
        )
    }


    const MenuButtons = (props) => {
        return (
            <MenuButton show={props.selected} onClick={() => setModal(props.action)}>
                <MenuIcon src={props.img} />
                <p>{props.heading}</p>
            </MenuButton>
        )
    }



    return (
        <BottomMenu>
            <Menu>
                <UtilModal modalShow={modalShow}  >
                    {
                        modalShow === "kopiera" ? <Kopiera setModal={setModal} /> :
                            modalShow === "karta" ? <Karta /> :
                                modalShow === "kunder" ? <Kunder setModal={setModal}/> :
                                    modalShow === "kamera" ? <Kamera /> :
                                        modalShow === "förstesällningar" ? <Förstesällningar /> : 
                                            modalShow === "rapport" ? <Rapport /> : ""
                    }

                </UtilModal>

            
                <MenuButtons heading="Kopiera" img="/copy.png" action="kopiera" selected={modalShow === "kopiera"} />
                <MenuButtons heading="Karta" img="/map.png" action="karta" selected={modalShow === "karta"} />
                <MenuButtons heading="Kunder" img="/clients.png" action="kunder" selected={modalShow === "kunder"} />
                <MenuButtons heading="Kamera" img="/camera.png" action="kamera" selected={modalShow === "kamera"} />
                <MenuButtons heading="Förbest..." img="/preorders.png" action="förstesällningar" selected={modalShow === "förstesällningar"}/>
                <MenuButtons heading="Rapport" img="/rapport.png" action="rapport" selected={modalShow === "rapport"}/>
            </Menu>
        </BottomMenu>
    )
}

export default UtilsMenu