import {useParams} from "react-router-dom"

const Details = () => {
    let params = useParams();
    return (
      <>
        {params.id}
      </>
    
    )
    }  

    export default Details