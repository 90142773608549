import {useParams} from "react-router-dom"
import {useState} from "react"
import CurrentAssignments from "../Data/CurrentAssignments";
import styled from "styled-components/macro"
import Dropdown from "../SystemModules/dropdown"
import UtilsMenu from "../Pages/UtilsMenu";

const Jobb = (props) => {

    
const [statusIndex, setStatusIndex] = useState(0)
const [toggleSwitchState, setSwitch] = useState(false)
const dropdownStationer = [
  { name: "Göteborg" },
  { name: "Kungälv" },
  { name: "Kungsbacka" },
]
const dropdownAvtal = [
  { name: "Inget avtal" },
  { name: "BMW Assistans" },
  { name: "Fiat Assistans" },
  { name: "Volvo Assistans" },
]
const [dropdown, setDropdown] = useState(dropdownAvtal[0].name)
const [dropdownAvtalSelected, setDropdownAvtalSelected] = useState(dropdownStationer[0].name)

const status = [
  {
  id: 0,
  name: "Uppfattat", 
  desc: "Du har blivit tilldelad ett nytt uppdrag, tryck på [Acceptera uppdrag] för att gå vidare.",
  buttons: [
    {caption: "Acceptera", style: "green",icon: "/cta_accept.png" ,action: 1, },
    {caption: "Tilldela", style: "blue",icon: "/cta_assign.png", action: "assign" },
  ]
},
  {
  id: 1,
  name: "Accepterat", 
  desc: "Dispatchen är informerad om att du accepterat uppdraget. Du kan antingen [Starta uppdrag] omedelbart eller avvakta lite genom att trycka på [Vänta]. Eftersom jobbet är utlagt för koordinering kan du välja en annan bärgare inom ditt område för att utföra uppdraget genom att trycka på [Tilldela].",
  buttons: [
    {caption: "Starta", style: "green",icon: "/cta_accept.png", action: 2},
    {caption: "Vänta", style: "blue",icon: "/cta_pause.png", action: 0},
    {caption: "Tilldela", style: "blue",icon: "/cta_assign.png", action: "assign"},
  ]
},
  {
  id: 2,
  name: "Startat", 
  desc: "",
  buttons: [
    {caption: "På plats", style: "green",icon: "/cta_accept.png", action: 3},
    {caption: "Avboka", style: "red",icon: "/cta_deny.png", action: 0},
    {caption: "Tilldela", style: "blue",icon: "/cta_assign.png", action: "assign"},
  ]
},
  {
  id: 3,
  name: "Vid upphämtningsplats", 
  desc: "På upphämtningsplatsen, tryck på [Transport påbörjad] när du lastat fordonet. Om det av någon anledning inte blir något uppdrag ändå kan du avboka det genom att trycka på [Avboka uppdrag].",
  buttons: [
    {caption: "Transport påbörjad",icon: "/cta_accept.png", style: "green", action: 4},
    {caption: "Avboka",icon: "/cta_deny.png", style: "red", action: 0},
    {caption: "Tilldela", style: "blue",icon: "/cta_assign.png", action: "assign"},
  ]
},
  {
  id: 4,
  name: "Transport startad", 
  desc: "Transport påbörjad, när du lastat av trycker du på [Klar vid destination].",
  buttons: [
    {caption: "Klar vid destination",icon: "/cta_accept.png", style: "green", action: 5},
    {caption: "Tilldela", style: "blue",icon: "/cta_assign.png", action: "assign"},
  ]
},
  {
  id: 5,
  name: "Klar vid destination", 
  desc: "Du är klar på destinationen, gå igenom uppgifterna under [Ekonomi] och [Information] för att säkerställa att det som krävs är ifyllt. Tryck sedan på [Skicka in].",
  buttons: [
    {caption: "Skicka in",icon: "/cta_accept.png", style: "green", action: 6},
    {caption: "Tillbaka",icon: "/cta_back.png", style: "blue", action: 4},
  ]
},
  {
  id: 6,
  name: "Avrapporterat", 
  desc: "Jobbet har skickats in.",
  buttons: [
    {caption: "Fakturering",icon: "/cta_accept.png", style: "green", action: "next"},

  ]
},
  {
  id: 7,
  name: "Tilldela", 
  desc: "Tilldela jobbet till en annan bärgare som är aktiv på någon av stationerna du är koordinator för.",
  buttons: [
    {caption: "Tillbaka", style: "green",icon: "/cta_back.png", action: 0},

  ]
},
]

    let params = useParams();
  
  
    const thisAssignment = CurrentAssignments.filter((x)=> x.id === params.id )
  
    const Wrapper = styled.div`
    position: relative;
    border: 1px solid #D0D0D0;
    height: calc(100vh - 350px);
	  padding: 1rem;
	  border: none;
    /* margin: 2rem ; */
    overflow-y: scroll ;
    font-size: 12px ;
    //FIX VALUE?
    height: calc(100vh - 282px - 2rem - 50px);
 
    @media(max-width: 1000px){
      // height: calc(100vh - 277px - 0rem - 24px);
      height: calc(100vh - 24.5rem);
    }
    `
    const Row = styled.div`
      display: grid;
      justify-items: start ;
      align-items: center;
      padding: 1rem 2rem ;
      grid-template-columns: 70% 30% ;
      /* grid-template-rows: 100px; */
      @media(max-width: 1000px){
        grid-template-columns: 50% 50%;
      }
      border-bottom: 1px solid #D0D0D0 ;
      &:nth-child(odd){
        background-color: #F9F9F9 ;
      }
    `
    const Button = styled.div`
      background-color: #00A65A ;
      padding: .5rem;
      @media(max-width: 1000px){
          padding: 0.3rem;
      }
      color: white;
      border-radius: 5px ;
    `
    const HeadingText = styled.div`
    font-weight: 400 ;
    color: gray;
    font-size: 15px;
    white-space: nowrap; 
    min-width: 5ch; 
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; 
    `

    const Text = styled.div`
    font-weight: 400 ;
    color: gray;
    font-size: 15px;
    `
    const Map = styled.div``
    const Caption = styled.div`
    color: gray;

    `
    const Heading = styled.h2``
    const TabHeader = styled.div`
    padding: 0 2rem;
    
    @media(min-width: 700px){
      display: flex;
      /* margin-bottom: 2rem; */
      
    }

    `

    const Status = styled.div`
      display: flex;
      flex: row ;
      /* justify-content: space-between ; */
      align-items: center ;
      max-width: 300px;
      p {
        margin: 0px;
        min-width: 100px ;
      }
    `

    const StatusView = styled.div`
      background-color: ${props => props.color === "green"?"#AFFFDB":props.color ==="yellow"?"yellow":"red" };
      color: ${props => props.color === "green"?"#00A65A;":props.color ==="yellow"?"#979700;":"red" };
      text-align: left ;
      width : fit-content ;
      /* margin-left: auto ; */
      padding: 0 .5rem;
      border-radius: 2rem ;
      font-weight: 600 ;
      font-size: 14px ;
          
    `

    const StatusDesc = styled.p`
    
    `

    const CTA = styled.div`
      cursor: pointer;
      padding: .5rem;
      text-align: center ;
      display: flex ;
      align-items: center ;
      font-size: 12px ;
      

      /* text-transform: uppercase ; */
      background-color: ${props => 
        props.type === "green"? "white":
        props.type === "blue"? "white":
        props.type === "red"? "white":""
        } ;
      margin: 1rem .5rem;
      color: ${props => 
        props.type === "green"? "#398C27":
        props.type === "blue"? "#58B7D4":
        props.type === "red"? "#F56060":""
        } ;
      border: 1px solid;
      border-radius: 10px ;
      border-color: ${props => 
        props.type === "green"? "#398C27":
        props.type === "blue"? "#58B7D4":
        props.type === "red"? "#F56060":""
        }  ;
      font-weight: 600 ;
      width: fit-content ;

    `

    const ButtonWrapper = styled.div`
      display: flex ;
      flex-direction: row ;
      justify-content: flex-start ;
      @media(max-width: 600px){
        justify-content: space-evenly ;
      }
    `

    const Icon = styled.img`
      margin-right: .5rem;
      width: auto;
      height: 20px ;
    `

    const JobId = styled.div`
      display: flex;
      align-items:center;
      font-size: 15px ;
      font-weight: 400;
      line-height: 15px;
      margin: 1rem 0;
      
      a {
        margin: 0;
        padding: 0;
        height: fit-content;
      }
    `

    const InfoRow = styled.div`
      display: flex ;
      flex-direction: column ;
    `

    const HeaderInfo = styled.div`
  
    `

    const Inline = styled.div`
    display: flex ;
    flex-direction: row ;
    margin-bottom: 1rem ;
    justify-content: flex-start ;
    `

    const Pen = styled.img`
      margin-right: 5px;
    `
 
      const BackArrow = styled.img`
      
      `

      const Toggle = ()=> {

        const ToggleWrapper = styled.div`
          cursor: pointer;
        `

        const ToggleSwitch = styled.div`
          background: ${props => props.state? "#00A65A": "#F56060"};
          display: flex; 
          flex-flow: ${props => props.state? "row": "row-reverse"};
          justify-content: space-evenly;
          align-items:center;
          
          width: 60px;
          border-radius: 27px;
          color: white;

        `

        const Knob = styled.div`
          height: 20px;
          width: 20px;
          background: #b7b7b7;
          border-radius: 50%;
        `

        return (
          <ToggleWrapper>
          
          <ToggleSwitch state={toggleSwitchState} onClick={()=> setSwitch(toggleSwitchState? false: true)}>
            <Knob/><p>{toggleSwitchState? "På": "Av"}</p>
          </ToggleSwitch>

          </ToggleWrapper>
          
          
        )
      }

    return (
      <>
      <TabHeader>
      {/* <Heading> Pågående uppdrag</Heading> */}
      <InfoRow>
      
      

      <JobId>
         <a  href="/mobilapp/newAssignment/"><Pen src="/blue_pen.png"/></a> {params.id}
      </JobId>
       
        <HeaderInfo>
     
      <Status><p>Status:</p> <StatusView color="green">{status[statusIndex].name}</StatusView></Status>
        </HeaderInfo>
      </InfoRow>
      <ButtonWrapper>
        {status[statusIndex].buttons.map((x)=> <CTA type={x.style} onClick={()=> setStatusIndex(x.action)}>
          <Icon src={x.icon} />
          {x.caption}
          </CTA> )}
      </ButtonWrapper>
      {/* <StatusDesc>Jobbstatus: {status[statusIndex].desc}</StatusDesc> */}
      
      </TabHeader>
      <Wrapper>
        <Row>
        <div>
        <HeadingText>Tungbärgning</HeadingText>
        </div>
        <Toggle/>
        </Row> 

        <Row>
        <div>
        <HeadingText>Telefon</HeadingText>
        <Caption> På plats </Caption>
        </div>
        <Button> 076 880 45 45</Button>
        </Row> 

        <Row>
        <div>
        <HeadingText>Telefon</HeadingText>
        <Caption> På plats </Caption>
        </div>
        <Button> 076 880 45 45</Button>
        </Row> 

        <Row>
      
        <HeadingText>Betalare</HeadingText>
        <div>
        {thisAssignment[0].invoiceTo.map((x)=> <Text> {x} </Text>)}
          <Text></Text>
        </div>
        </Row> 

        <Row>
        <HeadingText>Färg</HeadingText>
        <Text>{thisAssignment[0].color}</Text>
        </Row> 
      
        <Row>
        <HeadingText>Jobbtyp</HeadingText>
        <Text>{thisAssignment[0].type}</Text>
        </Row> 

        <Row>
        <HeadingText>Fordon</HeadingText>
        <Text>{thisAssignment[0].vehicle}</Text>
        </Row> 
    
        <Row>
        <HeadingText>Problembeskrivning</HeadingText>
        <Text>{thisAssignment[0].description}</Text>
        </Row> 
      
        <Row>
        <HeadingText>Hämtas</HeadingText>
        <Text>{thisAssignment[0].from}</Text>
        </Row> 
   
        <Row>
        <HeadingText>Lämnas</HeadingText>
        <Text>{thisAssignment[0].to}</Text>
        </Row> 
        
        <Row>
        <HeadingText>Station</HeadingText>
        <Text>{thisAssignment[0].station}</Text>
        </Row> 
      
      </Wrapper>
      <UtilsMenu/>
      </>
    )
    }  

    export default Jobb