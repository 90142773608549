const locations = 


        [
            {type: "loc", adress: "Ringögatan Gothenburg, Sweden"},
            {type: "loc", adress: "Ringön, Sweden"},
            {type: "loc", adress: "Ringsted Denmark"},
            {type: "loc", adress: "Avicii Arena, Ericsson Globen, Johanneshov"},
            {type: "loc", adress: "Västmannagatan 41, Stockholm"},
            {type: "loc", adress: "Globen, Ericsson Globen, Johanneshov"},
            {type: "loc", adress: "Hjälmaren, Hjälmarsvägen, Årsta"},
            {type: "loc", adress: "Bro Centrum, Blomstervägen, Bro"},
            {type: "loc", adress: "Elite Hotel Carolina Tower, Eugeniavägen, Solna"},
            {type: "loc", adress: "Westfield Mall of Scandinavia, Stjärntorget, Solna"},
       
        ]
        


export default locations