import styled from "styled-components"
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react"

const LeftCol = () => {

    const [url, setUrl] = useState("")
    
    useEffect(()=> {
        let url = window.location.href
       let currentUrl = url.split("/")

        setUrl(currentUrl[4])

    },[])


    const LeftColWrapper = styled.div`
    @media(max-width: 1000px){
        display:none;
    }
    // background-color: gray ;
    /* background: #e6e6e6; */
    grid-area: 3/1/1/1;
    a {
        text-decoration: none;
        color: inherit;
    }
`

    const LeftMenuItem = styled.li`
    list-style: none ;
    cursor: pointer;
    
    display: flex;
    align-items: center ;
    color: gray;
    /* background-color: #e6e6e6; */
    
    border-radius: 5px ;
    font-size: .8rem;
    padding: 1rem;
    margin: 1rem;
    margin-left: 2rem;

   

    
    background-color:  ${(props) => props.selected ? "#f1f1f1" : ""};
    border-radius: 0px;
        color: ${(props) => props.selected ? "#1c1c1c" : "gray"};
        border-radius: 0 5px 5px 0;
        border-left: ${(props) => props.selected ? "2px solid #47bcf4;" : "2px solid white;"}; */
    
  
`
    const LeftMenuIcon = styled.img`
    padding-right: .4rem;
`




    return (

        <LeftColWrapper>

            <Link to="/mobilapp/workpass" >
                <LeftMenuItem selected={url === "workpass"? true : false} >
                    <LeftMenuIcon src="/workpass.png" />Arbetspass
                </LeftMenuItem>
            </Link>

            <Link to="/mobilapp/currentassignmnets/">
                <LeftMenuItem selected={url === "currentassignmnets"? true : false} >
                    <LeftMenuIcon src="/blue_arrow.png" />Pågående jobb
                </LeftMenuItem>
            </Link>
            
            <Link to="/mobilapp/currentassignmnets/">
            <LeftMenuItem selected={url === "currentassignmnets2"? true : false} >
                <LeftMenuIcon src="/coffee.png" />Slutförda jobb
            </LeftMenuItem>
            </Link>
            
            <Link to="/mobilapp/newAssignment/">
                <LeftMenuItem selected={url === "newAssignment"? true : false} >
                    <LeftMenuIcon src="/left_menu_nyttjobb.png" />Nytt jobb</LeftMenuItem>
            </Link>


        </LeftColWrapper>
    )

}

export default LeftCol