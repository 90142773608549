import styled, {css} from "styled-components/macro"
import { useState } from "react";


const DropdownWrapper = styled.div`
      position: relative ;
        height: 50px ;
  
  
    width: ${props => props.width === "override"? "100%": "-webkit-fill-available"};
` 

const Dropdown = ({ selected, list, dropdown,setDropdown, title, type, align, width }) => {

    const Wrapper = styled.div`
         width: ${props => props.width === "override"? "100%": "max-content"}; 
        
        @media(max-width: 700px){
            max-width: 130px;

        } 
         cursor: pointer;
        min-width: 100px;
        
        margin-right: ${type === "blue"? "50px": "0px"};
        margin-top: ${type === "blue"? "5px": "0px"};
        background-color: white;
        ${type === "blue"? css`
        // background-color: blue;
        `:""}
        /* height: max-content; */
        border: 1px solid  ${type === "blue"? "#5ec5f6": "#398C27"};
        position: absolute;
        left: 0px;
    
      
        border-radius: ${type === "blue"?"0px": "15px"};
    `

    const Selected = styled.div`
        display: flex;
        align-items: center ;
        width: 100% ;
        padding: .3rem .4rem;
        /* width: max-content ; */
        font-size: 14px;
        ${type === "blue"? css`
        @media(max-width: 700px){
            font-size: 12px;
        }
        
        `:""}

        color: #4F4F4F;
    `

    const Item = styled.p`
    margin: 0.4rem 0;
    padding-left: 0.4rem;
    font-size: 14px;
    color: #4F4F4F;
    margin-top: 0px;
    

        &:hover {
            background-color: #57c2f5;
            color: white;
        }

    `
     const ListIcon = styled.img`
     height: 7px;
  width: auto;
  /* display: inline-block; */
  /* text-align: right; */
  margin-left: auto;
  padding-right: 17px;

  `
     const List = styled.div`
     display: flex;
     justify-content: space-between;
 `

    const DropdownTitle = styled.p `
        margin: 0;
        font-size: 12px ;
        padding: 0 .2rem;
    `
    
    const [menuState, showMenu] = useState(false)
    

    return (
        <DropdownWrapper width={width}>
            <DropdownTitle>{title}</DropdownTitle>
        <Wrapper width={width} onClick={() => showMenu(menuState ? false : true)}>

            <Selected >{selected}
                <ListIcon src="/dropdown.png" />
            </Selected>

            <List style={{ display: menuState ? "block" : "none" }}>
                {
                    list.map((x) =>
                        x.name !== dropdown ?
                            <Item onClick={() => setDropdown(x.name)}>{x.name}</Item>
                            : ""
                    )
                }
            </List>


        </Wrapper>
       </DropdownWrapper>
    )
}

export default Dropdown