import react from "react"
import styled from "styled-components/macro"

const List = ({list, cta}) => {

    const Wrapper = styled.div`
        padding: 1rem 0;
      
    `


    const Row = styled.div`
    padding: 1rem;
    border-bottom: 1px solid #9f9f9f;
    @media(max-width: 500px){
            padding: 1rem 0;
    }
    display: flex;
    flex-flow: row ;
    align-items: flex-start ;
        :hover {
            background-color: #d3d3d3 ;
        }
    `
    const TagWrapper = styled.div`
        display: flex ;
        flex-direction:  row;
        flex-wrap: wrap ;
        margin-top: 8px;
    `
    const Tag = styled.div`

        background-color: #47bcf4 ;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: .1rem .5rem;
        font-size: 12px ;
        color: white;
        border-radius: 5px ;
        @media(max-width: 500px){
            font-size: 10px;
           
    }

    `

    const Title = styled.h2`
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 700 ;
        color: #404040;
        @media(max-width: 500px){
            font-size: 12px;
    }
    `

    const SubTitle = styled.h3`
         margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 400 ;
        color: #404040;
        @media(max-width: 500px){
            font-size: 10px;
    }
    `

    const Icon = styled.img`
        height: 60px;
        width: 60px;
        border-radius: 5px ;
        margin-right: 1rem;
        object-position: left ;
        object-fit: cover ;
        @media(max-width: 500px){
            height: 50px;

            margin-right: 8px;
            width: 50px;
    }
    `

    const Content = styled.div`

    `

    const Cta = styled.div`
    @media(max-width: 500px){
        font-size: 10px;
        padding: 0.5rem 0.4rem;
    }
    border: 1px solid #47BCF4;
    height: fit-content;
    color: #47BCF4;
    border-radius: 5px ;
    padding: .5rem 1rem;
    font-weight: 600 ;
    font-size: 14px ;
    display: flex ;
    margin-left: auto;
    ::before {
        content: "";
        position: relative ;
        display: inline-block ;
        width: 20px ;
        background-image: url("${props => props.icon}");
        background-size: contain ;
        margin-right: 5px;
        height: 20px;
        @media(max-width: 500px){
            height: 15px;
            width: 15px;
    }
        /* background-color: #74BCF4 ; */
    }
    `

    const Arrow = styled.img`
        margin-left: auto;
        height: 60px ;
        width: 60px;
    `


    return(


        <Wrapper>
   
        {list.map((x)=> 


        <Row>

        
        <Icon src={x.icon || "/avatar.png"}/>

 
        <Content>    
            <Title>{x.name}</Title> 
            <SubTitle>{x.station}</SubTitle> 

            <TagWrapper>     
                {x.status.map((x)=> 
                <Tag>{x}</Tag>
                )}
            </TagWrapper>

        </Content>
            {cta === "arrow"? <Arrow src="/arrow_gray.png"/>:
            <Cta icon="/cta_assign.png">Tilldela</Cta>
            }
          
            </Row>

        )}
        </Wrapper>

    )
    }

export default List