import styled from "styled-components/macro"
import UtilsMenu from "../UtilsMenu";
import {useState} from "react"
import locations from "./demoData";
import verkstader from "./demoDataVerkstader";


const Karta = () => {

    const [modal, setModal] = useState(false)
    const [search, setSearch] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    let destinations = [
        { name: "Hölö", distance: "100", ort: "12146 Hölö", type: "Skadeplats", icon: "/map_red.png" },
        { name: "Mölmbo", distance: "100", ort: "12146 Hölö", type: "Vägpunkt", icon: "/map_gray.png" },
        { name: "Gnesta", distance: "100", ort: "12146 Hölö", type: "Destination", icon: "/map_green.png" },
    ]

    const Wrapper = styled.div`
        padding: 0 1rem;
        color: gray;
    `

    const Row = styled.div`
    display: flex;
    flex-flow: row;
    cursor: pointer;
    border-top: 1px solid #D9D9D9;
    padding: .5rem 0;
    `
    const Bold = styled.div`
        font-weight: 600;
        margin-left: 5px;
    `
    const Icon = styled.img`
        height: 60px;
        width: auto;
        margin-right: 10px;
    `
    const Heading = styled.div`
        font-weight: 600;
        color: #4F4F4F;
    `
    const Info = styled.div`
        display: flex; 
        flex-flow: column;
    `
    const Name = styled.div`
            font-size: 14px;
            font-weight: 400;
        `
    const Address = styled.div`
            font-size: 12px;
        
        `
    const Arrow = styled.img`
        margin-left: auto;
        height: 50px;
        width: auto;
    `
    const Line = styled.div`
        display: flex;
        flex-flow: row;
    `

    const MainHeading = styled.div`
        color: #4F4F4F;
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 1rem;
        
    `

    const ArrowDown = styled.img`
    height: 20px;
    width: auto;
    margin: 1rem;
    `

    const Distance = styled.div`
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
    `

    const Iframe = styled.iframe`
        width: 100%;
        border: none;

    `

    const DistanceInfo = styled.div`
        
    `

    const Modal = styled.div`
        width: 100vw;
        height: 100vh;
        background-color: white;
        position:absolute;
        top: 0;
        left: 0;
    `

    const Select = styled.div`
        display: ${props => props.show ? "block" : "none"};
        position: absolute;
        width: 100%;
        // padding: 1rem;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: white;
    `

    const SearchBox = styled.input`
        width: 100%;
        border-radius: 5px;
        font-size: 15px;
        box-sizing: border-box;
        padding: 1rem 0 ;
        padding-left: 50px;
        border: 1px solid #D9D9D9;
        &:focus {
            border: 1px solid #D9D9D9;
            outline: inherit;
        }
    `
    const SearchWrapper = styled.div`
        position: relative;
        margin: 1rem 0;
    `
    const SearchIcon = styled.img`
        position: absolute;
        top: 15px;
        left: 15px;
        height: 20px;
        widht: auto;
    `

    const Update = styled.div`
    
        color: #E1B134; 
        font-weight: 500;
        min-width: 200px;
        margin: auto 0;
        margin-left: auto;
        text-align: center;
        text-decoration: underline;
    `

    const SearchOverlay = styled.div`
        padding: 1rem;
        display: ${props => props.display? "block": "none"};
        background-color: #F2F2F2;
        width: 100%;
        color: #333333;
        box-sizing: border-box;
        `

    const Rows = styled.div`
    filter: ${search?"blur(3px)": "none"};
    
    `

    const Section = styled.div`
        
    `

    const SubHeading = styled.h3`
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #333333;
        
    `

    const ResRows = styled.div`
        :nth-child(n+6){
             display: none;
          
        }
    `

    const Results = styled.div``


    const ResultsRow = styled.div`
        display:flex; 
        flex-flow: row;
        align-items:center;
        img {
            height: 25px;
            margin-right: 1rem;
            width: auto;
        }
    `

    const ResultName = styled.div`
        display: block; 
        width: 100%;
    `
    const ResultAdress = styled.div`
        font-size: 12px;
    `
   
    const Flex = styled.div`
        display: flex;
        flex-flow: column;
    `

    const AddPoint = styled.div`
        display: flex;
        cursor: pointer;
        flex-flow: row;
        align-items: center;
        justify-content:center;
        img {
            width: 40px;
            margin-right: .5rem;
            height: 40px;
        }
    `

    const post = (e) => {
        // e.preventDefault()
        console.log("-->>", e?.target.value)
        setSearchValue(e?.target.value)
        if(e?.target.value !== ""){
            setSearch(true)
        } else{
            setSearch(false)
        }

        }


    return (
        <Wrapper>
            <MainHeading>Position</MainHeading>
            {destinations.map((x) =>
                <Row onClick={()=> setModal(x.type)} >
                    <Icon src={x.icon} />
                    <Info>
                        <Heading>{x.type}</Heading>
                        <Name>{x.name}</Name>
                        <Address>{x.ort}</Address>
                    </Info>
                    <Arrow src="/arrow_gray.png" />
                </Row>

            )
            }
            <AddPoint>
                <img src="/green_plus.png"/> <p>Lägg till vägpunkt</p>
            </AddPoint>
            <Distance>
                <ArrowDown src="/arrow_gray_down.png" />
                <DistanceInfo>
                    <Line>Beräknad körsträcka: <Bold>190km</Bold></Line>
                    <Line>Beräknad tid: <Bold>2:08:18</Bold></Line>
                </DistanceInfo>
            </Distance>
            <Iframe title="map" src="https://www.google.com/maps/embed?pb=!1m30!1m12!1m3!1d271913.7228377872!2d12.474591757254133!3d57.82975444732713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m15!3e0!4m3!3m2!1d57.7279068!2d11.993026299999999!4m5!1s0x465aa0b04bdcfeed%3A0x7c327e8fc1abfa59!2zQm9yw6Vz!3m2!1d57.721035!2d12.939819!4m3!3m2!1d58.051028499999994!2d12.7931826!5e0!3m2!1ssv!2sse!4v1667502583469!5m2!1ssv!2sse" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>


           

            
            <Select show={modal !== false}>
                <Wrapper>

                    <MainHeading>Ange {modal}</MainHeading>
                    <SearchWrapper>
                        <SearchIcon onClick={()=> setSearch(false) } src={search?"/backarrow_black.png": "/search.png"} />
                        <form action={()=> post()}>
                          <SearchBox autoFocus value={searchValue} onChange={(e)=> post(e)} placeholder="Sök plats, verkstad eller uppställningsplats" onClick={()=>setSearch(true)} />
                        </form>
                    </SearchWrapper>
{/* //SEARCH OVERLAY */}
                    <SearchOverlay display={search}>
                            <Section>
                            {/* {console.log(locations[0].type)} */}
                                
                                <SubHeading> Plats </SubHeading>
                                <Results>
                                    {locations.map((x)=> 
                                        
                                        <ResRows>
                                            <p>{searchValue? x.adress.toLowerCase().includes(searchValue,3)? <ResultsRow><img src="/marker_gray.png"/> {x.adress}</ResultsRow>:
                                            "": ""}</p>
                                        </ResRows>
                                    
                                    )}
                                </Results>
                            </Section>

                            <Section>
                                <SubHeading> Verkstäder  </SubHeading>   
                                <Results>
                                    {verkstader.map((x)=> 
                                        
                                        <>
                                            <p>{searchValue? x.adress.toLowerCase().includes(searchValue,3)? 
                                            <ResultsRow>
                                                <img src="/garage_gray.png"/> 
                                                <Flex>
                                                    <ResultName>{x.name}</ResultName> 
                                                    <ResultAdress>{x.adress}</ResultAdress>
                                                </Flex>
                                            </ResultsRow>:
                                            "": ""}</p>
                                        </>
                                    
                                    )}
                                </Results> 
                            </Section>

                            <Section>
                                <SubHeading> Uppställningsplatser </SubHeading>   
                                <Results>
                                    {verkstader.map((x)=> 
                                        
                                        <>
                                            <p>{searchValue? x.adress.toLowerCase().includes(searchValue,3)? 
                                            <ResultsRow>
                                                <img src="/p_gray.png"/> 
                                                <Flex>
                                                    <ResultName>{x.name}</ResultName> 
                                                    <ResultAdress>{x.adress}</ResultAdress>
                                                </Flex>
                                            </ResultsRow>:
                                            "": ""}</p>
                                        </>
                                    
                                    )}
                                </Results> 
                            </Section>
                    </SearchOverlay>

                    <Rows>

                    <Row onClick={()=>setModal(false)}>
                        <Icon src={"/map_pin.png"} />
                        <Info>
                            <Heading>Nuvarande koordinater</Heading>
                            <Name></Name>
                            <Address>59.211776, 17.596416</Address>
                        </Info>
                        {/* <Update>Uppdatera</Update> */}
                        <Arrow src="/green_plus.png" />
                    </Row>

                    <Row onClick={()=>setModal(false)}>
                        <Icon src={"/gray_marker.png"} />
                        <Info>
                            <Heading>Närmaste position</Heading>
                            <Name>STENLIDSVÄGEN 2</Name>
                            <Address>452 90 Strömstad, Sweden</Address>
                        </Info>
                        {/* <Update>Uppdatera</Update> */}
                        <Arrow  src="/green_plus.png" />
                    </Row>


                    <Row onClick={()=>setModal(false)}>
                        <Icon src={"/pin.png"} />
                        <Info>
                            <Heading>Knappnål på karta</Heading>
                            <Name></Name>
                            <Address>Släpp knappnål på kartan</Address>
                        </Info>
                        <Arrow src="/red_x.png" />
                    </Row>
            <Iframe title="map" src="https://www.google.com/maps/embed?pb=!1m30!1m12!1m3!1d271913.7228377872!2d12.474591757254133!3d57.82975444732713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m15!3e0!4m3!3m2!1d57.7279068!2d11.993026299999999!4m5!1s0x465aa0b04bdcfeed%3A0x7c327e8fc1abfa59!2zQm9yw6Vz!3m2!1d57.721035!2d12.939819!4m3!3m2!1d58.051028499999994!2d12.7931826!5e0!3m2!1ssv!2sse!4v1667502583469!5m2!1ssv!2sse" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>
                    </Rows>
                </Wrapper>

            </Select>


        </Wrapper>
    )
}

export default Karta