
import styled from "styled-components/macro"
import Dropdown from "../SystemModules/dropdown"
import { useState } from "react"


const Notes = () => {



    const användare = [
        { name: "Ingen användare" },
        { name: "Marcus Gustavsson" },
        { name: "Per Falk" },
        { name: "Stefan Jakobsson" }
    ]
    const jobblista = [
        { name: "Notering på ärende" },
        { name: "BX2T220500249-1" },
        { name: "BX2T220500249-2" },
        { name: "BX2T220500249-3" }
    ]

    const [dropDown1, setDropdown1] = useState("")
    const [dropDown2, setDropdown2] = useState("")

    const [notes, setNotes] = useState([
        { name: "Stefan Jakobsson", jobb: "bx2222000", message: "test message", at: "@Marcus", type: "int", date: "2022-08-23 08:23" },
        { name: "Stefan Jakobsson", jobb: "bx2222000", message: "test message", at: "@Marcus", type: "ext", date: "2022-08-23 08:23" },
        { name: "Stefan Jakobsson", jobb: "bx2222000", message: "test message", at: "@Marcus", type: "int", date: "2022-08-23 08:23" },
        { name: "Stefan Jakobsson", jobb: "bx2222000", message: "test message", at: "@Marcus", type: "int", date: "2022-08-23 08:23" },

    ])

    const [selected1, setSelected1] = useState("Ingen användare")
    const [selected2, setSelected2] = useState("Notering på ärende")

    const Wrapper = styled.div`
        height: calc(100vh - 277px - 6rem);
        padding: 2rem;
        padding-top: 0;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr 1fr  ;
        @media(max-width: 700px){
            height: calc(100vh - 10px - 6rem);
            padding: 0;
            overflow-x: hidden ;
            display: block;
        }

    `

    const TextArea = styled.textarea`
    resize: none ;
    overflow-y: auto ;
    padding: 1rem;
    font-family: "Open Sans" ;
    border: 1px solid gray;
    width: -webkit-fill-available;
    border-radius: 4px ;
    &:focus {
        border: 1px solid black;
        outline: none;
    }
    `

    const Button = styled.button`
        background: ${props => props.color};
        color: ${props => props.textColor};
        border-radius: 10px;
        border: none;
        width: 200px;
        margin-right: 1rem;
        padding: 1rem;
    `

    const Title = styled.h2`
       font-size: 17px ;
       color: gray;
       font-weight: 500 ;
    `

    const InputGrid = styled.div`
        // display: grid;
        // grid-template-columns: 1fr ;
        padding: 1rem;
        @media(max-width: 700px){
            padding-bottom: 0 ;
        }
    `

    const ButtonWrapper = styled.div`
        display: flex ;
        flex-direction: row ;
        margin-top: 1rem;
    `

    const DropdownWrapper = styled.div`
        display: flex ;
        flex-direction: row ;
        justify-content: center;
        align-items: center ;
        min-height: 100px ;
        margin-bottom: 0px ; //Dropdowns

     
    `

    const LogGrid = styled.div`
        padding: 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        @media(max-width: 700px){
            padding-top: 0 ;
        }

        h2 {
            @media(max-width: 700px){
            margin-top: 0 ;
        }
        }

    `

    const Row = styled.div`
        /* border: 1px solid gray; */
        margin-bottom: 5px;
        padding-bottom: 1rem;
        
    `

    const User = styled.h2`
      font-size: 20px;
      margin-bottom: 1px;
      margin-top: 4px;
      `

    const At = styled.p`
      color: gray;
      margin: 0px;
      font-size: 12px;
      `

    const Message = styled.p`
      margin: 0px;
      font-size: 18px;
    `

    const Date = styled.p`
        color: gray;
        margin: 0px;
        font-size: 12px;
    `

    const HeaderExternal = styled.div`
        background: #47bcf4;
        display: flex;
        padding: .5rem;
        color: white;
    `

    const HeaderInternal = styled.div`
        background: #c5c5c5;
        display: flex;
        padding: .5rem;
        color: white;
    `

    const DeleteRow = styled.div`
        margin-left: auto;
        cursor: pointer;
    `

    const Content = styled.div`
        padding: .5rem;
        padding-top: 0;
        margin-top: 10px;
        border-left: 2px solid #e6e6e6;
        margin-left: 0px;
        
    `


    function addToNotes(type) {

        setNotes(posts => {
            return [...posts, { name: "Stefan Jakobsson", jobb: "000", message: "Testnotering för jobb,", at: "@Marcus", type: type, date: "2022-08-23 08:23" }] // clone the array
        })


    }

    return (
        <Wrapper>


            <InputGrid>
                <Title>Skapa en notering i loggen</Title>
                <TextArea rows="7"></TextArea>
                <ButtonWrapper>
                    <Button onClick={() => addToNotes("ext")} color="#5ec5f6" textColor="white">Publicera externt</Button>
                    <Button onClick={() => addToNotes("int")} color="#f1f1f1" textColor="#1c1c1c">Publicera internt</Button>
                </ButtonWrapper>
                <DropdownWrapper>
                    <Dropdown type="blue" selected={selected1} list={användare} dropdown={selected1} setDropdown={setSelected1} title="Hänvisa till användare" />
                    <Dropdown type="blue" selected={selected2} list={jobblista} dropdown={selected2} setDropdown={setSelected2} title="Jobb/ärende:" />
                </DropdownWrapper>
            </InputGrid>

            <LogGrid>
                {notes.map((x) =>
                    <Row>
                        {x.type === "ext" ?

                            <HeaderExternal>Extern notifiering
                                <DeleteRow onClick={() => alert}>Ta bort</DeleteRow>
                            </HeaderExternal> :

                            <HeaderInternal>Intern notifiering
                                <DeleteRow onClick={() => alert}>Ta bort</DeleteRow>
                            </HeaderInternal>
                        }
                        <Content>
                            <User>{x.name}</User>
                            <At>{x.at}</At>
                            <Message>{x.message}</Message>
                            <Date>{x.date}</Date>
                        </Content>
                    </Row>
                )}
                <Title> Notifieringar</Title>



            </LogGrid>

        </Wrapper>

    )
}

export default Notes