import { Routes, Route, Link, useLocation, useParams  } from "react-router-dom";
import {useState} from "react"

import Jobb from "../Tabs/jobb"
import Order from "../Tabs/order"
import Copy from "../Modals/copy"
import Slider from '../SystemModules/slider';
import styled from "styled-components/macro"
import ViewAllJobs from "./ViewAllJobs";
import Details from "./Details";
import NotFound404 from "./404";
import CurrentAssignments from "../Data/CurrentAssignments";
import Notes from "./Notes";
import Footer from './Footer';
import TimeStamps from "./TimeStamps";
import Info from "./Info";

import LeftCol from "./Layouts/LeftCol"
import {Grid, App, Heading, MainWindow, TopRowWrapper} from "./Layouts/StyledComponents"


const MobilApp =({state, tabs, selectedId, idTabs, självrisk, setSjälvrisk, kontant, setKontant })=> {
    

    const [modalShow, setModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState("Jobb")


    const MenuButtons = (props)=> {
        return (
          <MenuButton onClick={()=> setModal(props.action) }>
            <MenuIcon src={props.img}/>
            <p>{props.heading}</p>
          </MenuButton>
        )      
      }


    const MenuIcon = styled.img`
        width:20px;
        height: auto ;
        margin: 0;
`


const MenuButton = styled.div`   
  width: fit-content ;
  display:flex ;
  flex-direction: column ;
  align-items:center ;
  justify-content:center ;
  padding:.5rem ;
p {
  color: white;
  margin: 0px;
  font-size:12px ;
}
`

const BackArrow = styled.img`
  margin-left: auto;
  padding: 1rem ;
  margin-top: -5px;
  display: block ;
  @media(max-width: 600px){
    display: none;
  }
`
const TabName = styled.h1`
text-align:center;
color: #474747;
font-weight: 600;
`
const UnitNumber = styled.h2`
text-align: center ;
color: #474747;
font-size: 1rem ;
font-weight: 400 ;
`
const BottomMenu = styled.nav`
height: 50px; 
width: 100%;
@media(max-width: 600px){
  display: flex;
}
background: linear-gradient(91.36deg, #43B0FF 1.53%, #4DD4FF 100%);
position: fixed ;
bottom: 0px;
display: none ;
flex-direction: row ;
justify-content: space-evenly ;
left: 50 ;
`


const RightCol = styled.div`
    // background-color: gray ;
`



const FlexRow = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  @media(max-width: 1000px){
    flex-direction: column ;
    width: initial;
  }
`

// const BackArrow = styled.img`
// margin-right: 1rem;
// max-height: 18px;
// `



    let location = useLocation();
    return (
    
        <App>
          
          {/* <BackArrow src="/backarrow.png"/> */}
      
            <Grid>
            
              <TopRowWrapper>
              <FlexRow>
              {/* <a href="/mobilapp/currentassignmnets/">
              <BackArrow src="/back_blue.png"/>
              </a>
              <Heading> Pågående uppdrag</Heading> */}
              

    

              <Slider TabData={tabs} idTabs={idTabs}/> 
              </FlexRow>
            
              
              </TopRowWrapper>
                  <LeftCol/>
              
              <MainWindow>
             
              {
              state === "order"? <Order självrisk={självrisk} kontant={kontant} setSjälvrisk={setSjälvrisk} setKontant={setKontant}/>:
              state === "jobb"? <Jobb självrisk={självrisk} kontant={kontant}/>:
              state === "details"? <Details selectedId={selectedId}/>:
              state === "start"? <ViewAllJobs CurrentAssignments={CurrentAssignments}/>:
              state === "time"? <TimeStamps/>:
              state === "notes"? <Notes/>:
              state === "info"? <Info/>:
              <NotFound404/>
              }
              </MainWindow>
              <RightCol></RightCol>
              
              

            </Grid>
              {/* <Footer/> */}
              
       
      
          {/* <BottomMenu>
              <MenuButtons heading="Kopiera" action="copy" img="/copy.png"/>
              <MenuButtons heading="Karta" img="/map.png"/>
              <MenuButtons heading="Kunder" img="/clients.png"/>
              <MenuButtons heading="Kamera" img="/camera.png"/>
              <MenuButtons heading="Förbest..." img="/preorders.png"/>
          </BottomMenu> */}

        </App>
        )
    }


    export default MobilApp