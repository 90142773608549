import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro"
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";
import CurrentAssignments from "../Data/CurrentAssignments";

const Slider = ({selectedTab, setSelectedTab, TabData, idTabs}) => {
  let location = useLocation();
  let current = location.pathname.split("/")

  let new1 = location.pathname
  let new2 = new1.replace("a", " ")
  let id = useParams()
  let ifParam = id.id || false
  
    const [scrollPos, setScrollPos] = useState(0)
    const containerRef = useRef(null);
    
  
    useEffect(() => {
        document.getElementById('slidemenu').scrollLeft = scrollPos
      });

    const MenuItem = styled(Link)`
        min-width: 120px;
        text-decoration: none ;
        background: ${props => props.selected?"white":""} ;
        border-left: ${props => props.selected? "2px solid #47bcf4": "none" };
        border-radius: 0 ;
        text-align: center ;
        padding: 0 .5rem;
        height: 40px ;
        height: 40px;
        font-size: .8rem;
        color: gray;
        margin: 0 .1rem;
        display: flex ;
        align-items: center ;
        justify-content: center ;
        &:active {
          color: gray;
        }
        &:visited: {
          color: gray;
        }
     
  `

    const SlideMenu = styled.div`
        width: 100% ;
        /* background-color: #E6E6E6 ; */
        background: linear-gradient(45deg, #f5f5f5, #f9f9f9);
        padding: .5rem;
        border-radius: 5px;
	      border-radius: 0px;
        justify-content: flex-start ;
        display: flex ;
        @media(max-width: 600px){
          
          border-radius: 0px ;
        }
        ::-webkit-scrollbar {
          height: 5px;
          cursor: pointer;
          
        }
        ::-webkit-scrollbar-thumb {
          background: #47bcf4;
          border-radius: 5px;
          margin: 1rem 1rem;
      
          cursor: pointer;
        }
        ::-webkit-scrollbar-track {
          border-radius: 0px;
          background:#E6E6E6;
          cursor: pointer;
         
        }
       
    `

    const MenuIcon = styled.img`
      margin-right: 5px;
      height: auto ;
      width: 16px ;
    `

    function MenuClick(name){
        setSelectedTab(name)    
        setScrollPos(containerRef.current.scrollLeft)
    }


    return (
      <SlideMenu id="slidemenu" ref={containerRef} innerRef={containerRef} onClick={()=> setScrollPos(containerRef.current.scrollLeft)}  >
    
        {TabData.map((x,i)=>
        
        <MenuItem to={`${x.url}/${id.id !== undefined? id.id:""}`} selected={ifParam? location.pathname  ===  x.url+ "/" + id.id: location.pathname  ===  x.url+ "/" }  >
          <>


          </>
         {x.icon? <MenuIcon src={x.icon}/>:""} {x.name} 

          {ifParam? "": 
        
            x.name === "Jobbpool"? " (0)": x.name === "Nyligen avslutade uppdrag"? " (0)": x.name === "Förbeställningar"? " (0)": x.name === "Pågående uppdrag"? " (" + CurrentAssignments.length+ ")":""
      
          }
        
         

        </MenuItem>
        )}
     
      </SlideMenu >

    )

}

export default Slider