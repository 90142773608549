import React from "react"
import styled from "styled-components/macro"

const Util = ({ type }) => {

    const Logout = () => {
        return (
            <>
                Logga ut
            </>
        )
    }

    const Search = () => {

        const SearchBar = styled.input`
            padding: .3rem;
            padding-left: 2rem;
            cursor: not-allowed;
        `
        const SearchIcon = styled.img`
            position: absolute ;
            left: 4px;
            top: 6px;
        `

        const Wrapper = styled.div`
            position: relative ;
        `

        return (
            <Wrapper>
                <SearchIcon src="/search.png" />
                <SearchBar />
            </Wrapper>
        )
    }

    const Settings = () => {
        const SettingsIcon = styled.img`
           height:25px ;
        width: auto ;
        `

        return (
            <>
                <SettingsIcon src="/settings.png" />
            </>
        )

    }



    const Notifications = () => {
        const NotificationIcon = styled.img`
        height:25px ;
        width: auto ;

        `

        return (
            <>
                <NotificationIcon src="/bell.png" />
            </>
        )

    }

    return (
        <>

            {
                type === "search" ?
                    <Search />
                    :
                    type === "logout" ?
                        <Logout />
                        :
                        type === "settings" ?
                            <Settings />
                            :
                            type === "notifications" ?
                                <Notifications />
                                : ""
            }


        </>

    )

}

export default Util