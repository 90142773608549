import styled from "styled-components"
import Dropdown from "../../SystemModules/dropdown"
import {useState} from "react"

const Rapport = () => {

    const [showHistory, setHistory] = useState(false)

    const [dropDown1, setDropdown1] = useState("")
    const [dropDown2, setDropdown2] = useState("")
    const [selected1, setSelected1] = useState("Mall 1")
    const [selected2, setSelected2] = useState("Mall 1")
    const lista = [
        {name: "Systemstandard"},
        {name: "Mall 1"},
    ]

    const Wrapper = styled.div`
        padding: 1rem;
        color: #696969;
    `

    const Header = styled.h2`
        font-weight: 400;
        margin-bottom: 1rem;
        font-size: 20px;
        text-align: center;
    `
    const Checker = styled.input`
      
    `
    const CheckerWrapper = styled.div`
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 1rem;
    `

    const ShowRapport = styled.div`
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-bottom:1rem;
        
        
        img {
            margin-left: .5rem;
            height: 40px;
            width: auto;
        }
    `

    const RowWrapper = styled.div`
        margin: 1rem 0;


        &::before {
            content: "${props => props.title}";
   
        }
    `

    const Row = styled.div`
        border-top: 1px solid gray;
        margin: .5rem 0;
        display: grid   ;
        grid-template-columns: 1fr 1fr 40px;
        height: 100px;
        align-items:center;
        @media(max-width: 400px){
            grid-template-columns: 1fr 1fr;
            
        }
        
    `

    const DropdownWrapper = styled.div`
        width: 100%;
        max-width: fit-content;
        margin: 0 auto;
        @media(max-width: 400px){
            grid-area: 2/1/2/2;
        }
    `

    const Col1 = styled.div`
        
        p {
            text-overflow: ellipsis;
            overflow: hidden; 
            width: 160px; 
            font-size: 12px;
            
            white-space: nowrap;
            

        }
    `

    const Label = styled.p`
        margin: 0;
    `

    const Input = styled.input`
        font-size: 12px;
        padding: .4rem;
        color: gray;
        margin-top: 4px;
        border-radius: 0;
        border: 1px solid gray;
    `

    const Heading = styled.h2`
        font-size: 16px;
        margin: 0;
    `
    const SubHeading = styled.h3`
        font-size: 14px;
        font-weight: 500;
        margin: 0;
    `
    const Email = styled.p`
        margin: 0;
    `

    const Icon = styled.img`
        width: 40px;
        height: 40px;
        margin-left: auto;
    `

    const History = styled.div`
        width: 100%;
        background: #219653;
        padding: 1rem;
        box-sizing: border-box;
        color: white;
        display:grid;
        grid-template-columns: 1fr 1fr;
        p {
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    `

    const HistoryHeader = styled.span`
        font-weight: 600;
        display:block;
        font-size: 14px;
    `

    const TopHeader = styled.div`
    background-color: green;
    color: white;
    font-size: 12px;
    padding: 5px 1rem;
    `
    const HistoryPrev = styled.div`
        width: 100%;
        background: #f6f6f6;
        padding: 1rem;
        box-sizing: border-box;
        color: #4f4f4f;
        display:grid;
        grid-template-columns: 1fr 1fr;
        p {
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    `

    const HistoryHeaderPrev = styled.span`
        font-weight: 600;
        display:block;
        font-size: 14px;
    `

    const TopHeaderPrev = styled.div`
    background-color: #f6f6f6;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 5px 1rem;
    `

    const PreviousLogs = styled.div`
        display: ${showHistory? "block": "none"};
        
    `

    const HistoryDropdown = styled.img`
        width: 15px;
        height: auto;
        margin-left: auto;
    `



    return(
        <Wrapper>
            <Header>Rapport</Header>
            <ShowRapport>
                <img src="/raport_gray.png"/>
                Visa rapport
            </ShowRapport>
            <DropdownWrapper>
               
                <Dropdown type="blue" selected={selected2} list={lista} dropdown={selected2} setDropdown={setSelected2} title="Rapportmall" />   
      
            </DropdownWrapper>
            <CheckerWrapper>
                <Checker type="checkbox" name="checker"/>
                <label for="checker">Inkludera alla jobb under ärendet</label> 
            </CheckerWrapper>

            <RowWrapper title="Skicka rapport">

                <Row>
                    <Col1>
                    <Label>E-post</Label>
                    <Input type="text"></Input>
                    </Col1>   

                    <DropdownWrapper>
                        <Dropdown type="blue" selected={selected1} list={lista} dropdown={selected1} setDropdown={setSelected1} title="Rapportmall" />   
                    </DropdownWrapper>
                    
                    <Icon src="/green_arrow.png"/>
                </Row>
                
            </RowWrapper>

            <RowWrapper title="Intressenter">

                <Row>
                    <Col1>
                        <Heading>Bärgningslogistik</Heading>
                        <SubHeading>Marcus Gustavsson</SubHeading>
                        <Email>marcus.gustavsson@bargningslogististik.se</Email>
                    </Col1>   

                    <DropdownWrapper>
                        <Dropdown type="blue" selected={selected2} list={lista} dropdown={selected2} setDropdown={setSelected2} title="Rapportmall" />   
                    </DropdownWrapper>
                    
                    <Icon src="/green_arrow.png"/>
                </Row>

            </RowWrapper>
       
            <RowWrapper title="Verkstäder">

                <Row>
                    <Col1>
                        <Heading>Autoexperten</Heading>
                        <SubHeading>Lars Ahalin</SubHeading>
                        <Email>la@autoexperten.se</Email>
                    </Col1>   

                    <DropdownWrapper>
                        <Dropdown type="blue" selected={selected2} list={lista} dropdown={selected2} setDropdown={setSelected2} title="Rapportmall" />   
                    </DropdownWrapper>
                    
                    <Icon src="/green_arrow.png"/>
                </Row>

            </RowWrapper>

            <TopHeader>Senaste:</TopHeader>
            <History>
                <>
                <p><HistoryHeader>Skickat:</HistoryHeader> 2022-12-05 15:40:21</p>
                <p><HistoryHeader>Skickat av:</HistoryHeader> Marcus</p>
                </>
                <>
                <p><HistoryHeader>Skickat till:</HistoryHeader> Autoexperten</p>
                <p><HistoryHeader>Mall:</HistoryHeader> standardmall</p>
                </>
            </History>
           
            <TopHeaderPrev onClick={()=> showHistory?setHistory(false): setHistory(true)}>Tidigare: <HistoryDropdown src="/dropdown.png"/></TopHeaderPrev>
            <PreviousLogs>
            <HistoryPrev>
                <>
                <p><HistoryHeader>Skickat:</HistoryHeader> 2022-12-05 15:40:21</p>
                <p><HistoryHeader>Skickat av:</HistoryHeader> Marcus</p>
                </>
                <>
                <p><HistoryHeader>Skickat till:</HistoryHeader> Autoexperten</p>
                <p><HistoryHeader>Mall:</HistoryHeader> standardmall</p>
                </>
            </HistoryPrev>
            <HistoryPrev>
                <>
                <p><HistoryHeader>Skickat:</HistoryHeader> 2022-12-05 15:40:21</p>
                <p><HistoryHeader>Skickat av:</HistoryHeader> Marcus</p>
                </>
                <>
                <p><HistoryHeader>Skickat till:</HistoryHeader> Autoexperten</p>
                <p><HistoryHeader>Mall:</HistoryHeader> standardmall</p>
                </>
            </HistoryPrev>
            </PreviousLogs>
        </Wrapper>

    )
}

export default Rapport