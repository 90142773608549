import styled from "styled-components/macro"
import {Link} from "react-router-dom"


const ViewAllJobs = ({CurrentAssignments}) => {

    const Card = styled.div`
      width: 300px;
      background-color: #F9F9F9 ;
      margin: 1rem ;
      box-shadow: 4px 5px 9px #dfdfdf;
      overflow: hidden ;
      font-size: 14px ;
      @media(max-width: 700px){
        width: initial ;
        box-shadow: none;
        
      
      }
     
    `
    
    const Wrapper = styled.div`
      display: flex;
      overflow-y: scroll ;
      height: calc(100vh - 289px + 4rem);
      flex-wrap: wrap;
      justify-content: flex-start ;
      align-items: flex-start ;
      @media(max-width: 700px){
        height: calc(100vh - 169px + 4rem);
        justify-content: center ;
        display: block ;
      }

      a {
        text-decoration: none ;
        color: inherit;
      }
    
      &::selection {
        color: #24569a;
        background: #9ec0df;
      }

    `

    const Header = styled.div`
    background-color: #47BCF4;

    background-color: ${props=> 
      props.cardStatus === "normal" ? "#47bcf4":
      props.cardStatus === "warning" ? "orange":
      props.cardStatus === "alert" ? "#F56060":
      props.cardStatus === "preorder" ? "#F56060":""
      };

    width: 100% ;
    color: white;
    grid-area: 3/1/1/1;
    padding: .5rem;

    @media(max-width: 700px){
      background-color: #f9f9f9;
    }

    `

    const Type = styled.div`
      font-weight: 900 ;
    `

    const Vehicle = styled.div`
      padding-bottom: 1rem ;
      margin-bottom: 1rem ;
      border-bottom: 1px solid black ;
    `

    const CardBody = styled.div`
      padding: 1rem;
    `

    const IconRow = styled.div`
      display: grid ;
      padding: .5rem 0 ;
      grid-template-columns: 10% 90% ;
      align-items: center;
    `

    const Icon = styled.img`
      margin: 0 auto;
      padding-right: 0.5rem ;
    `

    const Line = styled.div`
      border-bottom: 1px solid black ;
      border-bottom: 1px solid #6d6d6d;
      margin: 0 1rem;
    `
    const CardLink = styled.div`
      display: flex ;
      justify-content: flex-end ;
      align-items: center ;
      padding: .5rem ;
      font-size: 20px ;
      @media(max-width: 700px){
        display: none ;
      }
      p {
        margin-right: 1rem ;
        color: #4f4f4f;
      }
    `

    const Details = styled.div`
      @media(max-width: 700px){
        display: none;
      }
    `

    const HeaderInfo = styled.div`
        display: grid ;
        grid-template-columns: 70% 30% ;
      @media(min-width: 700px){
        display: none ;
      }

      `

      const LeftCol = styled.div`
        background-color: #f9f9f9;
        padding: 0.5rem;
      
        border-left: 5px solid ${props=> 
        props.cardStatus === "normal" ? "#47bcf4":
        props.cardStatus === "warning" ? "orange":
        props.cardStatus === "alert" ? "#F56060":
        props.cardStatus === "preorder" ? "#F56060":""
        };
        color: gray;
  
      p {
        margin: 0px ;
        padding: 0px ;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1; 
        -webkit-box-orient: vertical;
      }

      h3 {
        margin: 0px ;
        margin-bottom: 1rem ;
        padding: 0px ;
        display: flex;
        align-items: center;
        color: ${props=> 
        props.cardStatus === "normal" ? "#47bcf4":
        props.cardStatus === "warning" ? "orange":
        props.cardStatus === "alert" ? "#F56060":
        props.cardStatus === "preorder" ? "#F56060":""
        };
      }
    `

    const RightCol = styled.div`
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    ` 

    const HeaderInfoSingle = styled.div`
      display: flex;
      align-items:center;
      @media(max-width: 700px){
        display: none ;
      }
    `

    const CTA = styled.div`
      cursor: pointer;
      padding: .5rem;
      text-align: center ;
      display: flex ;
      flex-direction: column ;
      align-items: center ;
      font-size: 12px ;

      background-color: ${props => 
        props.type === "green"? "white":
        props.type === "blue"? "white":
        props.type === "red"? "white":""
        } ;

      color: ${props => 
        props.type === "green"? "#398C27":
        props.type === "blue"? "#58B7D4":
        props.type === "red"? "#F56060":""
        } ;
      border: 1px solid;
      border-radius: 10px ;
      border-color: ${props => 
        props.type === "green"? "#398C27":
        props.type === "blue"? "#58B7D4":
        props.type === "red"? "#F56060":""
        }  ;

      font-weight: 600 ;
      width: fit-content ;

    `

    const Icon2 = styled.img`
      margin-right: .5rem;
      width: auto;
      height: 20px ;
    `

    const IDIcon = styled.img`
      margin-right: 5px ;
      max-height: 14px;
    `

    const Bold = styled.span`
      font-weight: 600 ;
      color: #4c4c4c;
      color: ${props => props.color}; 

      
    `

    const BoldDesktop = styled.div`
    color: ${props => props.color}; 
    p {
          margin: 0;
          padding: 0;

        }
    `

    return (
      <Wrapper>
      {
        CurrentAssignments.map((x)=> 
        <Link to={`jobb/${x.id}`}>
        <Card>
          
          <Header cardStatus={x.cardStatus} >
            <HeaderInfoSingle>
              {
                x.cardStatus === "normal" ? <IDIcon src="/white_arrow.png"/>:
                x.cardStatus === "warning" ?<IDIcon src="/warning_w.png"/>:
                x.cardStatus === "alert" ? <IDIcon src="/warning_w.png"/>:
                x.cardStatus === "preorder" ? <IDIcon src="/preorder_w.png"/>:""
              }
              {x.id}
            
            </HeaderInfoSingle>

            <HeaderInfo>
              <LeftCol cardStatus={x.cardStatus}>
                <h3>  
                  {
                      x.cardStatus === "normal" ? <IDIcon src="/blue_arrow.png"/>:
                      x.cardStatus === "warning" ?<IDIcon src="/warning.png"/>:
                      x.cardStatus === "alert" ? <IDIcon src="/cta_deny.png"/>:
                      x.cardStatus === "preorder" ? <IDIcon src="/preorder.png"/>:""
                  }
                  
                
                  {x.id} <p></p>
                </h3>
                <Bold color={
                   
                     x.cardStatus === "warning" ?"orange":
                     x.cardStatus === "alert" ? "#F56060":
                     x.cardStatus === "preorder" ? "#F56060":""
                }>{x.statusMsg?.map((msg)=> <p>{msg}</p>)}</Bold>
                <p> Jobb: <Bold> {x.type}</Bold></p>
                <p> Status: <Bold color="#398C27">Uppfattat</Bold></p>
                <p> Regnr: <Bold>XDZ 361</Bold></p>
                <p> Hämtas: <Bold>Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden</Bold></p>
                <p> Lämnas: <Bold>Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden</Bold></p>
                <p>Fordon:<Bold> {x.vehicle} </Bold></p>
              </LeftCol>

              <RightCol>
                <img src="/cardLink.png"/>
              </RightCol>

            </HeaderInfo>  
          </Header>
            <Details>
          <CardBody>
          <BoldDesktop color={
                   x.cardStatus === "warning" ?"orange":
                   x.cardStatus === "alert" ? "#F56060":
                   x.cardStatus === "preorder" ? "#F56060":""
              }>{x.statusMsg?.map((msg)=> <p>{msg}</p>)}</BoldDesktop>

            <Type>{x.type}</Type>
            <Vehicle>{x.vehicle}</Vehicle>
              <IconRow><Icon src="/car.png"/>{x.regnr}</IconRow>
              <IconRow><Icon src="/cardmap.png"/>{x.from}</IconRow>
              <IconRow><Icon src="/cardarrow.png"/></IconRow>
              <IconRow><Icon src="/cardmap.png"/>{x.to}</IconRow>
              <IconRow><Icon src="/date.png"/>{x.date}</IconRow>
              <IconRow><Icon src="/station.png"/>{x.station}</IconRow>
          </CardBody>

          <Line/>
            </Details>
            <CardLink><p>Visa jobb</p> <img src="/cardLink.png"/></CardLink>          
        </Card>

        </Link>
        )
      }
      </Wrapper>
    )
    
}

export default ViewAllJobs