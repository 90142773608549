import LeftCol from "./Layouts/LeftCol"
import {App, Grid, LeftColWrapper, Heading, MainWindow, TopRowWrapper} from "./Layouts/StyledComponents"
import styled from "styled-components/macro"
import Dropdown from "../SystemModules/dropdown"
import {useState} from "react"
const Workpass = () => {

    const list = [
        {name:"Koordinator"}, 
        {name:"Bärgare"}, 
        {name: "Ej Aktiv"}
    ]
    const lista = [
        {name:"Inget fordon valt"}, 
        {name:"XDZ 361"}, 
        {name: "ABC 123"}
    ]

const [dropDown1, setDropdown1] = useState("")
const [dropDown2, setDropdown2] = useState("")
const [dropDown3, setDropdown3] = useState("")
const [dropDown4, setDropdown4] = useState("")

const [selected1, setSelected1] = useState("Koordinator")
const [selected2, setSelected2] = useState("Bärgare")
const [selected3, setSelected3] = useState("Ej Aktiv")
const [selected4, setSelected4] = useState("Inget fordon valt")

// const [dropdown, setDropdown] = useState(priceLists[0].name)



const FlexWrapper = styled.div`
    display: flex ;
    overflow-y: auto ;
    width: 1040px; //Limit 3
    flex-direction: row ;
    @media(max-width: 700px){
        height: calc(100vh - 105px);
        display: block ;
        width: auto;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    }
   
`

const Card = styled.div`
     width: 300px;
     background-color: #F9F9F9;
     margin: 1rem auto;
     color: ${props => props.color};
    box-shadow: 4px 5px 9px #dfdfdf;
    overflow: hidden;
    font-size: 14px;
`
const TopBorder = styled.div`

display: flex ;
background-color: ${props => props.background} ;
width: 100% ;
padding: .5rem ;
color: white;
p {
    margin: 0 ;
    font-weight: 600 ;
    margin-right: 1rem;
}

`
const Icon = styled.img`
    margin-right: 4px;
`
const InfoBox = styled.div`
    padding-right: 1rem;
`
const Content = styled.div`
    background-color: white;
    padding: 1.5rem;
`
const TopRow = styled.div`
    display: grid ;
    grid-template-columns:1fr 1fr ;
`
const SubTitle = styled.h3`
    margin: 0;
`

const Title = styled.h2`
margin: 0;
`

const Lower = styled.div`
margin: 0;
    p {
        margin: 0;
    }
    h4 {
        margin:0;
        margin-top: 1rem;
    }
`

const HeadingTop = styled.div`
    font-size: 20px;
    color: gray;
    padding: 1rem;
    
`

const RedButton = styled.div`
    border: 1px solid #F56060;
    padding: .5rem;
    color: #F56060;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 600;
    font-size:12px;
    display: flex;
    align-items:center;
    cursor: pointer;
    img {margin-right: .5rem;}
    `
    
    const GreenButton = styled.div`
    border: 1px solid #398C27;
    box-sizing: border-box;
    cursor: pointer;
    padding: .5rem;
    color: #398C27;
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
    font-size:12px;
    display: flex;
    align-items:center;
    img {margin-right: .5rem;}
    `

const CtaWrapper = styled.div`
    display: flex;
    flex-flow: row;
    width: fit-content;
    justify-content: space-evenly;
  
`

const Col1 = styled.div`
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        p {
            text-overflow: ellipsis;
            overflow: hidden; 
            // width: 160px; 
            font-size: 12px;
            
            white-space: nowrap;
            

        }
    `

    const Label = styled.p`
        margin: 0;
    `

    const Input = styled.input`
        font-size: 13px;
        padding: 0.4rem;
        color: gray;
        box-sizing: border-box;
        width: 150px;
        margin-top: 5px;
        /* margin-right: 0.5rem; */
        border-radius: 5px;
        border: 1px solid #a8a8a8;
    `
    const DropdownWrapper = styled.div`
        width: 100%;
        max-width: fit-content;
        margin: 0 auto;
        @media(max-width: 400px){
            grid-area: 2/1/2/2;
        }
    `

    const Inputs = styled.div`
        display: flex;

    `

    const BottomWrapper = styled.div`
        display: flex
    `

    const InputGrid = styled.div`
        display: grid;
        grid-template-columns: repeat(2,calc(50% - 5px));
        grid-gap: 10px;
        max-width: 350px;
        margin: .5rem;
        @media(min-width: 500px){      
    }

        `



return (
<App>
    
    <Grid>
    <TopRowWrapper>
        <HeadingTop>
        Arbetspass


    </HeadingTop>
    </TopRowWrapper>
    
        <LeftCol/>
        <MainWindow>

        <InputGrid>
          
                <GreenButton><img src="/cta_accept.png"/>Ange Arbetspass</GreenButton>

                <RedButton><img src="/cta_deny.png"/>Avsluta alla</RedButton>
           
                <Col1>
                    <Label>Alternativ telefon</Label>
                    <Input placeholder="+46" type="text"></Input>
                </Col1>   
                    
                <Dropdown type="blue" selected={selected4} list={lista} dropdown={selected4} setDropdown={setSelected4} title="Fordon" />   
                    
            
        </InputGrid>   

            <FlexWrapper>
                <Card color={selected1 === "Ej Aktiv"? "gray": "black"}> 
                    <TopBorder background={selected1 === "Koordinator" ? "#097CB2" : selected1 === "Bärgare"? "#09B27F" : selected1 === "Ej Aktiv"? "#9E9E9E":""}> 
                        <p>Göteborg</p>
                        <InfoBox ><Icon src="../workpass_car.png"/>5 bärgare</InfoBox>
                        <InfoBox ><Icon src="../workpass_person.png"/>Per Falk</InfoBox>
                    </TopBorder>
                        <Content>
                            <TopRow>
                                <div>
                                <SubTitle> Station</SubTitle>
                                <Title> Göteborg</Title>
                                </div>
                                <div>
                                    <Dropdown  selected={selected1} list={list} dropdown={selected1} setDropdown={setSelected1} title="Roll"/>
                                </div>
                                </TopRow>
                                <Lower>
                                    <p> Bärgare aktiva: 4</p>
                                    <p> Koordinator: Per Falk</p>
                                    <h4> Information</h4>
                                    <p> Stationen koordinerad av Per Falk sedan 2022-06-17 07:24. Just nu är 4 bärgare aktiva på stationen.</p>
                                </Lower>
                            
                        </Content>
                </Card>

                <Card color={selected2 === "Ej Aktiv"? "gray": "black"}>
                    <TopBorder background={selected2 === "Koordinator" ? "#097CB2" : selected2 === "Bärgare"? "#09B27F" : selected2 === "Ej Aktiv"? "#9E9E9E":""}> 
                        <p>Göteborg</p>
                        <InfoBox ><Icon src="../workpass_car.png"/>5 bärgare</InfoBox>
                        <InfoBox ><Icon src="../workpass_person.png"/>Saknas</InfoBox>
                    </TopBorder>
                        <Content>
                            <TopRow>
                                <div>
                                <SubTitle> Station</SubTitle>
                                <Title> Kungälv</Title>
                                </div>
                                <div>
                                    <Dropdown  selected={selected2} list={list} dropdown={selected2} setDropdown={setSelected2} title="Roll"/>
                                </div>
                                </TopRow>
                                <Lower>
                                    <p> Bärgare aktiva: 3</p>
                                    <p> Koordinator: Saknas</p>
                                
                                    <h4> Information</h4>
                                    <p> Stationen saknar koordinator. Just nu är 3 bärgare aktiva på stationen.</p>
                                </Lower>
                            
                        </Content>
                </Card>

                <Card color={selected3 === "Ej Aktiv"? "gray": "black"}>
                    <TopBorder background={selected3 === "Koordinator" ? "#097CB2" : selected3 === "Bärgare"? "#09B27F" : selected3 === "Ej Aktiv"? "#9E9E9E":""}> 
                        <p>Kungsbacka</p>
                        <InfoBox ><Icon src="../workpass_car.png"/>2 bärgare</InfoBox>
                        <InfoBox ><Icon src="../workpass_person.png"/>Per Falk</InfoBox>
                    </TopBorder>
                        <Content>
                            <TopRow>
                                <div>
                                <SubTitle> Station</SubTitle>
                                <Title> Göteborg</Title>
                                </div>
                                <div>
                                    <Dropdown  selected={selected3} list={list} dropdown={selected3} setDropdown={setSelected3} title="Roll"/>
                                </div>
                                </TopRow>
                                <Lower>
                                    <p> Bärgare aktiva: 4</p>
                                    <p> Koordinator: Per Falk</p>
                                
                                    <h4> Information</h4>
                                    <p> Stationen koordinerad av Per Falk sedan 2022-06-17 07:24. Just nu är 2 bärgare aktiva på stationen.</p>
                                </Lower>
                            
                        </Content>
                </Card>
 

            </FlexWrapper>
        </MainWindow>
    </Grid>
</App>

)
}

export default Workpass