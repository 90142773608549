import styled from "styled-components"

const Info = () => {

    const Logdata = [
        {
        type: "",
        time: "",
        event: "",
        comment: "",
        }
    ]

    const Wrapper = styled.div`
        overflow-y: auto;

    `

    const Row = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 1rem;

        display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        
      
          
          
        details {
            @media(max-width: 700px){
            font-size: 12px ;
        }
        }

        :nth-child(even){
            background-color: #F9F9F9; ;
        }

        font-weight: ${props => props.type === "header"? "600":"200"};
        background-color: ${props => props.type === "header"? "#e6e6e6":""};
        color: ${props => props.type === "header"? "#464646":""};
    `

    const Type = styled.div` 
        @media(max-width: 700px){
            font-size: 12px ;
        }
    `
    const Time = styled.div`
      @media(max-width: 700px){
            font-size: 12px ;
        }`
    const Event = styled.div`
     @media(max-width: 700px){
            font-size: 12px ;
        }`
    const Comment = styled.div`
     @media(max-width: 700px){
            font-size: 12px ;
        }`

const Title = styled.h2`
font-size: 17px ;
margin-left: 1rem;
color: gray;
font-weight: 500 ;
`

    return (
        <Wrapper>
            <Title> Information</Title>
            <Row type="header">
                <Type>Kategori</Type>
                <Time>Tid</Time>
                <Event>Händelse</Event>
                <Comment>Kommentar</Comment>
            </Row>

        <Row type="dispatch">
            <Type> Dispatch</Type>
            <Time>17/06 08:10</Time>
            <Event>Slutfört</Event>
            {/* <Comment>BX2T210600222-2 Stefan Jakobsson - Göteborg - Koordinator</Comment> */}
            <details>
                <summary>Kommentar</summary>
                <p>BX2T210600222-2</p>
                <p> Stefan Jakobsson </p>
                <p> Göteborg </p>
                <p> Koordinator </p>
            </details>
        </Row>
        
        <Row type="dispatch">
            <Type> Dispatch</Type>
            <Time>17/06 08:10</Time>
            <Event>Slutfört</Event>
            {/* <Comment>BX2T210600222-2 Stefan Jakobsson - Göteborg - Koordinator</Comment> */}
            <details>
                <summary>Kommentar</summary>
                <p>BX2T210600222-2</p>
                <p> Stefan Jakobsson </p>
                <p> Göteborg </p>
                <p> Koordinator </p>
            </details>
        </Row>
        <Row type="dispatch">
            <Type> Dispatch</Type>
            <Time>17/06 08:10</Time>
            <Event>Slutfört</Event>
            {/* <Comment>BX2T210600222-2 Stefan Jakobsson - Göteborg - Koordinator</Comment> */}
            <details>
                <summary>Kommentar</summary>
                <p>BX2T210600222-2</p>
                <p> Stefan Jakobsson </p>
                <p> Göteborg </p>
                <p> Koordinator </p>
            </details>
        </Row>
        <Row type="dispatch">
            <Type> Dispatch</Type>
            <Time>17/06 08:10</Time>
            <Event>Slutfört</Event>
            {/* <Comment>BX2T210600222-2 Stefan Jakobsson - Göteborg - Koordinator</Comment> */}
            <details>
                <summary>Kommentar</summary>
                <p>BX2T210600222-2</p>
                <p> Stefan Jakobsson </p>
                <p> Göteborg </p>
                <p> Koordinator </p>
            </details>
        </Row>
        </Wrapper>
    )

}

export default Info