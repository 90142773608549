import logo from '../logo.svg';
import '../App.css';
import styled from "styled-components/macro"
import { useState, useRef, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { ScrollTo } from "react-scroll-to";
import useStayScrolled from 'react-stay-scrolled';
import CurrentAssignments from '../Data/CurrentAssignments';
import Util  from '../SystemModules/utils';
import { Routes, Route, Link, useLocation  } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import MobileMenu from './MobileMenu';
import MobilApp  from './MobileApp';
import Workpass  from './workpass';

import NewAssignment from './newAssignment';

const MainApp = ()=> {

 
  const [självrisk, setSjälvrisk] = useState(0)
  const [kontant, setKontant] = useState(0)
  const [showMoblieMenu, setMobileMenu] = useState(false)


const MobilAppTabs = [
  {name: "Jobbpool", url: "#"},
  {name: "Nyligen avslutade uppdrag", url: "#"},
  {name: "Förbeställningar", url: "#"},
  {name: "Pågående uppdrag", url: "/mobilapp/currentassignmnets"},
]

const CurrentassignmentsTabs = [
  {name: "Jobb", url:"/mobilapp/currentassignmnets/jobb", icon: "/menu_jobb.png", heading: "Pågående jobb", tel1: "0768804545", tel2: "0768804545", invoiceTo: "Trygghansa", vehicle: "Citroen", color: "Silver", problem: "Låsöppning", desc: "Problem med lås", location: "Västberga, Hägersten-Liljeholmen, Stockholm, Sweden"    },
  {name: "Order", url:"/mobilapp/currentassignmnets/order",icon: "/menu_order.png", heading: "Order"},
  {name: "Notering", url:"/mobilapp/currentassignmnets/notes",icon: "/menu_notes.png",},
  {name: "Tidstämplar", url:"/mobilapp/currentassignmnets/time",icon: "/menu_time.png",},
  {name: "Information", url:"/mobilapp/currentassignmnets/info",icon: "/menu_info.png",},
]



const mainNav = [
  {title: "Nytt ärende", url: "/newcase"},
  {title: "Dispatch", url: "/dispatch"},
  {title: "MobilApp", url: "/mobilapp/currentassignmnets/"},
  {title: "Ekonomi", url: "/economy"},
  {title: "Mitt företag", url: "/company"},
  {title: "Inställningar", url: "/settings"},
]

const Wrapper = styled.div`
    display: block;
    min-width: 320px;
    margin: 0 auto ;
    height: 100vh;
    overflow: hidden ;
    font-family: "Open Sans" , "system-ui";
`

const TopNav = styled.div`
    display: flex;
    flex-direction: row;
    height:50px ;
    position: relative ;
    padding: 1rem; //new
`
const Logo = styled.img`
    width: auto ;
    margin: 30px;
    margin-top: auto ;
    margin-bottom: auto ;
    height: 20px ;
`
const MainNav = styled.div`
    display: flex;
    margin: auto  0;
    flex-direction: row;
    @media(max-width: 1000px){
      display:none;
    }
`
const NavItem = styled(Link)`
    margin: 0 1rem;
    color: #474747;
    text-decoration: none ;
    font-weight: 300;
    color: gray;
`
const UtilsBar = styled.div`
    display: flex;
    cursor: not-allowed ;
    margin: auto 10px auto auto;
    width: 200px ;
    justify-content: space-between ;
    flex-direction: row;
    @media(max-width: 1000px){
      display:none;
    }
`

const Main = styled.div`
  font-size: 20px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
  height: 100vh ;
  font-weight: 900 ;
  color: #ebebeb;
  font-size: 150px ;
  @media(max-width: 700px){
    font-size: 40px;
  }
`

const LandingPage = () => {
  return (
    <>
      <Main>Bravo X2</Main>
    </>
  )
}

const Hamburger = styled.img`
  margin-left: auto;
  @media(min-width: 1000px){
    display:none;
  }
`

let location = useLocation();
// let selectedId = location.pathname.replace("/mobilapp/currentassignmnets/details/", "")

return (
  <Wrapper>
       
  <TopNav>
  <Logo src="/logo.png"/>
  <MainNav> 
    {mainNav.map((x)=>  <NavItem style={{borderBottom: location.pathname === x.url ?" 1px solid #488C9B":"none"}} to={x.url}>{x.title}  </NavItem>)}
  </MainNav>

    <UtilsBar>
      {/* <Util type="search" /> */}
      <Util type="logout"/>
      <Util type="settings"/>
      <Util type="notifications"/>
    </UtilsBar>

    <Hamburger src="/mobilemenu.png"onClick={()=> setMobileMenu(true)}/>
  <MobileMenu show={showMoblieMenu} setMobileMenu={setMobileMenu}/>
</TopNav>

  <Routes>
    <Route path="/" element={ <LandingPage/> }  />

      <Route path="/mobilapp/currentassignmnets/order/:id" element={ <MobilApp state="order" tabs={CurrentassignmentsTabs} idTabs="true" kontant={kontant} setKontant={setKontant} självrisk={självrisk} setSjälvrisk={setSjälvrisk} />} />
      <Route path="/mobilapp/currentassignmnets/details/:id" element={ <MobilApp state="details"  tabs={CurrentassignmentsTabs} idTabs="true"/>} />
      <Route path="/mobilapp/currentassignmnets/jobb/:id"  element={ <MobilApp state="jobb"  tabs={CurrentassignmentsTabs} idTabs="true" kontant={kontant} setKontant={setKontant} självrisk={självrisk} setSjälvrisk={setSjälvrisk}/>} />
      <Route path="/mobilapp/currentassignmnets/notes/:id" element={ <MobilApp state="notes" tabs={CurrentassignmentsTabs} idTabs="true"/>} />
      <Route path="/mobilapp/currentassignmnets/time/:id" element={ <MobilApp state="time" tabs={CurrentassignmentsTabs} idTabs="true"/>} />
      <Route path="/mobilapp/currentassignmnets/info/:id" element={ <MobilApp state="info" tabs={CurrentassignmentsTabs} idTabs="true" />} />
      <Route path="/mobilapp/currentassignmnets" element={ <MobilApp state="start" tabs={MobilAppTabs} />} />
      <Route path="/mobilapp/preorders" element={ <MobilApp state="preorders"   />} />
      <Route path="/mobilapp/finnished" element={ <MobilApp state="finnished" />} />
      <Route path="/mobilapp/assignmentpool" element={ <MobilApp state="assignmentpool" /> } />
      <Route path="/mobilapp" element={ <MobilApp state="start" tabs={MobilAppTabs} /> }  />
      <Route path="/mobilapp/workpass" element={ <Workpass /> }  />
      <Route path="/mobilapp/newAssignment" element={ <NewAssignment/> }  />

 </Routes>

  </Wrapper>

)
}

export default MainApp