
import { Routes, Route, Link, useLocation  } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import MainApp from "./Pages/MainApp"

import React from "react";



function App() {

  return (
    <Router>  
      <MainApp/>
    </Router>
  );
}


export default App;
