import styled from "styled-components"
import {useState, useEffect} from "react"
import { Link } from "react-router-dom"
const MobileMenu = (props) => {

    const [url, setUrl] = useState("")

    useEffect(()=> {
        let url = window.location.href
       let currentUrl = url.split("/")

        setUrl(currentUrl[4])

    },[])

    const Wrapper = styled.div`
        display: ${props.show? "block": "none"} ;
        overflow-y: auto ;
        flex-direction: column ;
        background-color: white; 
        position: absolute ;
        top: 0;
        left: 0;
        align-items: flex-end ;
        z-index: 999;
        width: 100% ;
        height: 100vh;
    `
    const MenuRow = styled.div`
        padding: 1rem 0rem;
        padding-left: ${props => props.sub? "3rem": "2rem"} ;
        margin: 1rem ;

    
        background-color: ${props => props.selected?"rgb(241, 241, 241)": "white"};

        color: rgb(28, 28, 28);
        border-radius: 0px 5px 5px 0px;
        border-left: 2px solid rgb(71, 188, 244);
        a {
            text-decoration: none;
            color: inherit;
        }
        `   

        const Icon = styled.img`
            margin-right: 1rem;
        `

        const Logo = styled.img`
            padding: 1rem 0 ;
            width: auto;
            height: 20px;
            width: fit-content;
           
            
        `

        const TopRow = styled.div`
            display:flex;
            flex-flow: row;
            justify-content: space-evenly;
            padding: 0 1rem ;
            align-items:center;
          
        `
        const SystemIcon = styled.img`
            height: 20px;
            width: auto;
            margin-left: 1rem;
        
        `

   


    return(
        <Wrapper onClick={()=> props.setMobileMenu(false)}>
            <TopRow>
                <Logo src="/logo.png"/>
        
                
                    <SystemIcon src="/settings.png"/>
                    <SystemIcon src="/bell.png"/>
                    <SystemIcon src="/search.png"/>
           
                </TopRow>
            <MenuRow><Link to="#"> Nytt ärende</Link></MenuRow>
            <MenuRow><Link to="#"> Dispatch</Link></MenuRow>
            <MenuRow ><Link to="/mobilapp/currentassignmnets/"> MobilApp</Link></MenuRow>
            <MenuRow selected={url === "workpass"} sub={true}>
                <Link to="/mobilapp/workpass"><Icon src="/workpass.png"/>  Arbetspass</Link></MenuRow>
            <MenuRow selected={url === "currentassignmnets"} sub={true}><Link to="/mobilapp/currentassignmnets/">
                <Icon src="/blue_arrow.png"/> Pågående jobb</Link></MenuRow>
            <MenuRow  sub={true}><Link to="/mobilapp/currentassignmnets">
                <Icon src="/coffee.png"/> Slutförda jobb</Link></MenuRow>
            <MenuRow selected={url === "newAssignment"} sub={true}><Link to="/mobilapp/newAssignment/">
                <Icon src="/left_menu_nyttjobb.png"/> Nytt jobb</Link></MenuRow>
            <MenuRow><Link to="#"> Ekonomi</Link></MenuRow>
            <MenuRow><Link to="#"> Mitt företag</Link></MenuRow>
            <MenuRow><Link to="#"> Inställningar</Link></MenuRow>
  
        
      
        </Wrapper>

    )
}

export default MobileMenu