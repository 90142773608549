import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components/macro"
import Dropdown from "../SystemModules/dropdown"


const Order = (props) => {
    const scroller = useRef(null)

    const priceLists = [
        { name: "15%" },
        { name: "20%" },
        { name: "Helg" },
    ]
    const [dropdown, setDropdown] = useState(priceLists[0].name)



    // const scrollToBottom = () => scroller.current.scrollBy(0, 500)
  


    const [cart, updateCart] = useState([{ artikel: "Start", antal: 1, taxonomy: "st", rabatt: 2, pris: 400, locked: false }])
  
    const [totalPrice, setTotalPrice] = useState()
    
    const [textField, updateTextfield] = useState()
    const [scrollPos, setScrollPos] = useState(0)
    const [showProductList, setShowProductList] = useState(false)

    console.log(cart)

    useEffect(() => {

        scroller.current.scrollTo({
            top: scrollPos,
            left: 0
        });
        console.log(scroller.current)
        console.log(scrollPos)
    }, [scrollPos])

    const result = cart.reduce((total, cart) => total = total + (cart.pris * cart.antal) * ((1 - (cart.rabatt / 100))), 0);






    const Wrapper = styled.div`
      margin: 0 auto;
      height: 100% ;
      padding: 2rem;
      @media(max-width: 700px){
        padding: 0rem;

        }
    `
    const Toprow = styled.div`
        display: flex;
        padding: .5rem;
        flex-flow: row;
        /* justify-content: space-between; */
        position: sticky ;
        top: 0px;
        background-color: white ;
    `

    const Button = styled.div`
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    justify-content: center ;
    align-items: center;
    font-size: 15px;
    background-color: white;
    margin: 1rem 0.5rem;
    color: #398C27;
    border: 1px solid;
    border-radius: 10px;
    border-color: #398C27;
    font-weight: 600;
    min-width: 120px ;

    /* width: fit-content; */
    `
 

   

    const Content = styled.div`
        /* height: calc(100vh - 396px); */
        overflow-y: scroll ;
        z-index: 999 ;
        height: calc(100vh - 500px);
        @media(max-width: 700px){
            height: calc(100vh - 398px);
            min-height: 100px;

        }

       
    `

    const Row = styled.div`
    display: ${props => props.hide? "none": "flex"};
    flex-flow: row ;
    padding: .5rem;

    &:nth-child(even){
        background-color: #efefef;

    }

        
    `

   

    const addToCart = (data) => {


        updateCart(posts => {

            return [...posts, data] // clone the array
        })

    }

    const deleteItem = (i) => {
        updateCart(cart.filter((item, index) => index !== i))
    }

    const updateItem = (e) => {
        e.preventDefault()
        updateCart(posts => {
            posts[e.target.index.value] = {
                artikel: e.target.fieldArtikel.value,
                rabatt: e.target.fieldRabatt.value,
                pris: parseInt(e.target.fieldPris.value),
                taxonomy: e.target.taxonomy.value,
                antal: e.target.fieldAntal.value,
                locked: e.target.locked.value
            }
            return [...posts]
        })
    }

    const ArtikelField = styled.input`
        display:none ;
   
    `

    const Osd = styled.div`
        display: flex ;
        flex-direction: row ;
        align-items: center ;
        margin-left: auto;
        
    `

    const Update = styled.button`
        color: orange;
        text-decoration: underline;
        cursor: pointer;
        /* height: 40px; */
        margin-left: 5rem ;
        background: none ;
        border: none ;
    `

    const Delete = styled.button`
        background: none ;
        border: none ;
        color: red;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 2rem ;
    `

    const TitleRow = styled.div`
        display: flex ;
        flex-flow: row ;
        max-width: 700px ;
        /* margin-bottom: 1rem ; */
        h3 {
            margin: 0px;
            font-size: 1rem ;
            color: #525252;
            @media(max-width: 700px){
                font-size: 10px;
            }
        }

    `

    const Field = styled.input`
        height: 2rem ;
        border: 1px solid #B5B5B5; 
        font-family: "open sans" ;
        color: gray;
        padding-left: .5rem ;
        &&:focus {
            outline: none ;
            outline: 1px solid #49bdf4;
            transform: scale(1.01) ;
        }
       
        width: ${props => props.width};
        @media(max-width: 600px){
            width: 100%;
        
        }
        &:read-only {
            background-color: #d3d3d3 ;
        }
    `

    const Taxonomy = styled.div`
        background-color: #B5B5B5 ;
        color: white;
        padding: 0 .5rem;
        display: flex ;
        align-items: center ;
        margin-right: 1rem; 
        @media(max-width: 600px){
            /* margin-right: calc(100vw/60);  */
            padding: 0 .2rem;
        }
    `
    const FieldGroupRow = styled.div`
        display: flex ;
        flex-flow: row ;
        
        
        `
    const FieldGroupCol = styled.div`
        display: flex ;
        flex-flow: column ;

     
            color: #525252;
        
      
     
    `

    const InputField = styled.div`
        display: flex ;
        flex-direction: row ;
    `

    const Total = styled.div`
         /* height: 150px;  */
        /* position: sticky ; */
        @media(max-width: 600px){ bottom: 50px ;}
        bottom: 0px;
        background: white ;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        padding: 1rem;
        border-top: 1px solid gray;
    `



    const LargeHeading = styled.p`
        font-weight: 900 ;
        font-size: 1.2rem ;
        margin: 0;
    `

    const Text = styled.p`
    margin: 0;
    
    `
    const LargePrice = styled.p`
        font-weight: 900 ;
        margin: 0;
        font-size: 2rem ;
        padding: 0 ;
    `
    const SmallPrice = styled.p`
        font-weight: 900 ;
        font-size: 1rem ;
        margin: 0;
    `

    const ModalAddtoCart = styled.div`
        display: none ;
    `

    const DropDownWrapper = styled.div`
        position: relative ;
   
        width: -webkit-fill-available;
     
    `

   

    const ProductList = styled.div`
        height: calc(100vh - 50px);
        padding: 1rem;
        /* overflow: scroll; */
        background-color: #F9F9F9;
      
        display: ${props => props.show ? "block" : "none"};

    `

    const produkter = [
        { namn: "Ny rad", pris: 0, taxonomy: "st", locked: false, antal: 1, rabatt: 0 },
        { namn: "Avgår betalt på plats (kort)", pris: 0, taxonomy: "st", locked: true, antal: 1, rabatt: 0 },
        { namn: "Avgår betalt på plats (swish)", pris: 0, taxonomy: "st", locked: true, antal: 1, rabatt: 0 },
        { namn: "GS Utbildning Tung", pris: 750, taxonomy: "h", rabatt: 30, locked: false, antal: 1 },
        { namn: "TMA Enhetstaxa dag", pris: 550, taxonomy: "h", rabatt: 30, locked: false, antal: 1 },
        { namn: "TMA Enhetstaxa jour", pris: 790, taxonomy: "h", rabatt: 30, locked: false, antal: 1 },
        { namn: "TMA timpris ordinarie", pris: 490, taxonomy: "h", rabatt: 30, locked: false, antal: 1 },
        { namn: "TMA timpris övertid", pris: 390, taxonomy: "h", rabatt: 30, locked: false, antal: 1 },
    ]

    const ProductRow = styled.div`
    background-color: white ;
    margin-bottom: 1rem;
    max-width: 500px ;
    padding: 1rem;
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    justify-content: space-between ;
    `

    const AddToCart = styled.div`
        cursor: pointer;
        background-color: #47BCF4 ;
        width: fit-content ;
        font-size: 12px ;
        color: white;
        font-weight: 600 ;
        padding: .7rem ;
        display: flex;
        flex-direction: row;
        justify-content: center ;
        align-items: center ;
        border-radius: 25px ;
        margin-left: auto ;

    `

    const AddToCartIcon = styled.img`
       margin-right: .6rem ;
       height: 15px ;
       width: auto ;

    `

    const CartPrice = styled.div`
        background-color: #8AC77D;
        border-radius: 5px ;
        color: white;
        width: fit-content ;
        font-size: 12px ;
        padding: .2rem ;
`

    const ProductInfo = styled.div`
        width: 150px ;
    `

    const TotalInputField = styled.input`
    
    `

    const InputTotalWrapper = styled.div`
        display: flex ;
        flex-direction:column ;
        p {
            margin: 0;
        }
    `

    return (
        <Wrapper>
            
            <Toprow>

                    <Dropdown selected={dropdown} list={priceLists} dropdown={dropdown} setDropdown={setDropdown} title="Prislista"/>
            
                <div>
                    <Button onClick={() => {
                        setShowProductList(true)
                        //    setScrollPos(scroller.current.scrollTop )

                        //     addToCart(
                        //     {
                        //         artikel: document.getElementById("artikel").value,
                        //         pris: parseInt(document.getElementById("pris").value) || 0,
                        //         rabatt: document.getElementById("rabatt").value,
                        //         antal: document.getElementById("antal").value,
                        //     }
                        // ) 
                    }

                    }>Lägg till artikel</Button>

                    <ModalAddtoCart>
                        <input type="text" id="artikel" />
                        <input type="text" id="pris" />
                        <input type="text" id="rabatt" />
                        <input type="text" id="antal" />
                    </ModalAddtoCart>
                </div>
            </Toprow>
            <Content id="scroller" ref={scroller}>

            <ProductList show={showProductList} onClick={() => setShowProductList(false)}>
                {produkter.map((x) =>

                    <ProductRow onClick={() => addToCart(
                        {
                            artikel: x.namn,
                            pris: parseInt(x.pris),
                            rabatt: x.rabatt,
                            antal: x.antal,
                            locked: x.locked,
                            taxonomy: x.taxonomy
                        }
                    )
                    }>
                        <ProductInfo>
                            {x.namn}
                            {x.pris ?
                                <CartPrice>{x.pris} kr/{x.taxonomy}</CartPrice>
                                : ""}
                        </ProductInfo>
                        <AddToCart><AddToCartIcon src="/plus.png" />Lägg till i order</AddToCart>
                    </ProductRow>
                )
                }
            </ProductList>

                {
                    cart.map((x, i) =>
                        <Row hide={showProductList}>
                            <form onSubmit={updateItem} >
                                {/* {console.log(cart)} */}
                                <TitleRow>

                                    <h3> {x.artikel || "Title"}</h3>
                                    <Osd>
                                        <Update onClick={() => updateItem()}>  Uppdatera</Update>
                                        <Delete onClick={() => deleteItem(i)}> Ta bort</Delete>
                                    </Osd>

                                    <ArtikelField type="text" placeholder="Artikel" defaultValue={x.artikel} id="fieldArtikel" />
                                </TitleRow>

                                <InputField>

                                    <FieldGroupCol>
                                        <label for="fieldPris">Pris</label>
                                        <FieldGroupRow>
                                            <Field width={"75px"} type="number" placeholder="Pris" defaultValue={x.pris || 0} id="fieldPris" />
                                            {console.log(typeof (x.antal))}
                                            <Taxonomy>kr</Taxonomy>
                                        </FieldGroupRow>
                                    </FieldGroupCol>

                                    <FieldGroupCol dir="column">
                                        <label for="fieldAntal">Antal</label>
                                        <FieldGroupRow>

                                            {x.locked ?
                                                <Field width={"50px"} type="text" placeholder="Antal" defaultValue={x.antal} id="fieldAntal" readOnly />
                                                :
                                                <Field width={"50px"} type="text" placeholder="Antal" defaultValue={x.antal} id="fieldAntal" />
                                            }
                                            <Taxonomy>{x.taxonomy}</Taxonomy>
                                        </FieldGroupRow>
                                    </FieldGroupCol>

                                    <FieldGroupCol dir="column">
                                        <label for="fieldRabatt">Rabatt</label>
                                        <FieldGroupRow>
                                            <Field width={"100px"} type="number" placeholder="Rabatt" defaultValue={x.rabatt} id="fieldRabatt" />
                                            <Taxonomy>%</Taxonomy>
                                        </FieldGroupRow>
                                    </FieldGroupCol>

                                    <FieldGroupCol dir="column">
                                        <label for="result">Totalt</label>
                                        <FieldGroupRow>
                                            <Field width={"100px"} type="number" placeholder="pris" id="result" value={(x.pris * x.antal) * ((1 - (x.rabatt / 100))) || 0} />
                                            <Taxonomy>kr</Taxonomy>
                                        </FieldGroupRow>
                                    </FieldGroupCol>

                                    <Field type="hidden" value={i} id="index" />
                                    <Field type="hidden" value={x.taxonomy} id="taxonomy" />
                                    <Field type="hidden" value={x.locked} id="locked" />

                                </InputField>
                            </form>
                        </Row>
                    )
                }


            </Content>
            <Total>
                <div>



                    <LargeHeading>Summa:</LargeHeading>
                    <Text>Ex moms</Text>
                    <LargePrice>{result} kr</LargePrice>
                </div>

                <div>
                    {/* <Text>Ex moms</Text>
                    <SmallPrice>{result * 0.8} kr</SmallPrice> */}
                   

                        <FieldGroupCol dir="column">
                            <label for="sjalvrisk">Självrisk</label>
                            <FieldGroupRow>
                                <Field width={"200px"} type="text" placeholder="sjalvrisk" defaultValue={props.självrisk} id="sjalvrisk"
                                onChange={() => {
                                    setTimeout(() => {
                                        props.setSjälvrisk(document.getElementById("sjalvrisk").value)
                                        document.getElementById("sjalvrisk").focus()
                                    }, 1000)
                                }}
                                />
                                <Taxonomy>kr</Taxonomy>
                            </FieldGroupRow>
                        </FieldGroupCol>
                                    
                        <FieldGroupCol dir="column">
                            <label for="kontant">Kontant</label>
                            <FieldGroupRow>
                                <Field width={"200px"} type="text" placeholder="kontant" defaultValue={props.kontant} id="kontant"
                                onChange={() => {
                                    setTimeout(() => {
                                        props.setKontant(document.getElementById("kontant").value)
                                        document.getElementById("kontant").focus()
                                    }, 1000)
                                }}
                                />
                                <Taxonomy>kr</Taxonomy>
                            </FieldGroupRow>
                        </FieldGroupCol>

        
                </div>

            </Total>
        </Wrapper>
    )
}

export default Order