import styled from "styled-components"
import {useState} from "react"

const Kamera = () => {
    
    const [overlay, setOverlay] = useState(false)
    const [modal, setModal] = useState(false)
    const Wrapper = styled.div`
        position: relative;
        padding: 0 2rem;
    `
    const Heading = styled.div`
        color: #4F4F4F;
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
    `

    const UploadBox = styled.div`
    `
    const UploadButton = styled.div`
        border: 2px dotted gray;
        cursor: pointer;
        padding: 1rem;
        display: flex;
        justify-content: space-evenly;
        align-items:center;
        border-radius: 7px;
        max-width: 200px;
        text-align: center;
        margin: 0 auto;
        color: #8C8C8C;
        font-weight: 200;
        font-size: 25px;

        h2 {

        }

        img {

        }
    `

    const UploadButtonDone = styled.div`
     
        cursor: pointer;
        padding: 1rem;
        
 
        text-align: center;
        margin: 0 auto;
        color: #8C8C8C;
        font-weight: 200;
        font-size: 25px;
        position: sticky;
        bottom: 0px;
        background: white;
        width: 100%;
        h2 {

        }

        img {

        }
    `

    const Row = styled.div`
        display: flex; 
        margin-bottom: 1rem;
        border-bottom: 1px solid gray;
        padding-bottom: 1rem ;
        
    `

    const Image = styled.img`
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 10px;
    `
    const Title = styled.div`
   
        font-weight: 600;
        color: #4F4F4F;
        font-size: 16px;
        `

    const Date = styled.div`
        color: gray;
        font-size: 14px;
        font-weight: 400;
        `

    const ImageList = styled.div`
        margin-top: 2rem;
    `

    const Data = styled.div`
        padding-left: 1rem;
        display: flex;
        flex-flow: column;
    `

    const Edit = styled.img`
        height: 16px;
        widht: auto;
        margin-left: auto;
    `

    const ImageEditOverlay = styled.div`
        display: ${props => props.display? "block": "none"};
        min-height: 100vh;
        width: 100%;
        position: absolute;
        top: 0px;
        background: white;
        left: 0px;
        padding: 0 2rem;
        box-sizing: border-box;
    `

    const FullsizeImage = styled.img`
        width: 100%;
        height: 500px;
        object-fit: contain;
    `

    const Input = styled.div`
        
    `
    const Label = styled.div``
    const Field = styled.input`
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 1rem ;
    // padding-left: 50px;
    border: 1px solid #D9D9D9;
    &:focus {
        border: 1px solid #D9D9D9;
        outline: inherit;
    }
        box-sizing: border-box;
    `
    const TextArea = styled.textarea`
    width: 100%;
    border-radius: 5px;
    font-family: inherit;
    font-size: 15px;
    box-sizing: border-box;
    padding: 1rem ;
    // padding-left: 50px;
    border: 1px solid #D9D9D9;
    &:focus {
        border: 1px solid #D9D9D9;
        outline: inherit;
    }
        box-sizing: border-box;
    `

    const Upload = styled.div`
        display: flex; 
        position: sticky;
        bottom: 0px;
        background: white;
        cursor: pointer;
        flex-flow: column;
        img {
            width: 40px;
            height: 40px;
        }
        justify-content:center;
        align-items: center;

        p {
            color: gray;
            font-size: 20px;
            margin: 0;
            padding: 0;
        }
    `

    const ListTitle = styled.div`
        border-bottom: 1px solid gray;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        color: gray;
        font-size: 14px;
        font-weight: 100;
    `

    const Cta = styled.div`
        cursor: pointer;
        background: ${props => props.type==="delete"? "white": "#8AC77D"};
        width: 200px;
        text-align:center ;
        text-decoration: ${props => props.type==="delete"? "underline": "none"}; ;
        padding: 1rem;
        margin: 0 auto;
        margin-top: .5rem;
        border-radius: 50px ;
        color: ${props => props.type==="delete"? "red": "white"};
    `

    return (
        <Wrapper>
            <Heading>Kamera</Heading>
      
            <ImageList>
                <ListTitle>Sparade foton</ListTitle>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car1.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car2.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car3.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
            </ImageList>

            <Upload onClick={()=> setModal(true)}>
                <img src="/green_plus.png" />
                <p>Nytt foto</p>
            </Upload>

            <ImageEditOverlay display={modal}>
                <UploadButton>Nytt foto
                    <img src="/green_plus.png" />
                </UploadButton>
                <ImageList>
                <ListTitle>Tillagda foton</ListTitle>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car1.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car2.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
                <Row onClick={()=> setOverlay(true)}>
                    <Image src="/car3.jpeg" />
                    <Data>
                        <Title>BX2T220400242-1</Title>
                        <Date>2022-11-16 13:30</Date>
                    </Data>
                    <Edit src="/gray_pen.png" />
                </Row>
            </ImageList>
            <UploadButtonDone>Ladda upp
                   
                </UploadButtonDone>
            </ImageEditOverlay>

            <ImageEditOverlay display={overlay}>
                <FullsizeImage src="/car3.jpeg"/>
                {/* <Input >
                <Label>
                    Filnamn
                </Label>
                <Field placeholder="DCIM20221011"/>
                </Input>
                
                <Input>
                <Label>
                    Kommentar
                </Label>
                <TextArea rows="6"/>
                </Input> */}
            <Cta onClick={()=> setOverlay(false)}>Uppdatera</Cta>
            <Cta type="delete">Ta bort</Cta>
            </ImageEditOverlay>
        </Wrapper>
    )
}

export default Kamera