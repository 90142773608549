const CurrentAssignments = [
    {id: "BX2T220400242-1", type: "Bärgning", vehicle: "Audi A4", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"normal", statusMsg: []},
    {id: "BX2T220400242-2", type: "Bärgning", vehicle: "Volvo V70", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"warning", statusMsg: ["Ej avslutad"] },
    {id: "BX2T220400242-3", type: "Bärgning", vehicle: "BMW M3", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"alert", statusMsg: ["Order saknas"]},
    {id: "BX2T220400242-4", type: "Bärgning", vehicle: "Scania CR19", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"preorder", statusMsg: ["Förbeställning", "Hämtas: 2022-10-01 07:00", "Lämnas: 2022-10:01 11:30"]},
    {id: "BX2T220400242-5", type: "Bärgning", vehicle: "Volvo FM12", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"normal",statusMsg: []},
    {id: "BX2T220400242-6", type: "Bärgning", vehicle: "Seat Ibiza", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"normal",statusMsg: []},
    {id: "BX2T220400242-7", type: "Bärgning", vehicle: "Skoda Oktavia", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"normal",statusMsg: []},
    {id: "BX2T220400242-8", type: "Bärgning", vehicle: "VW Golf", regnr: "XDZ 361",date: " 22/4 10:27", station: "Göteborg", description:"Låset har slutat fungera, står på parkerningsplats vid Coop Forum i Västberga", invoiceTo: ["Trygghansa", "Tungbärgning AB"], Phone: "0768804545",   from: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", to: "Hägersten, Hägersten-Liljeholmen, Stockholm, Sweden", cardStatus:"normal",statusMsg: []},
  ]

  export default CurrentAssignments